import React from "react";

const AssetInnerBanner = () => {
  return (
    <section className="assets_inner_banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-12  align-items-center">
            <div className="home_banner_content">
              <h1 className="wow fadeInUp mt-60" data-wow-delay="0.3s">
                EXTREME TEST <span>ASSETS</span>
              </h1>
              <p className="wow mr-150 mt_30 ml-150 fadeInUp" data-wow-delay="0.4s">
                “FireFlink uses AI based NLP techniques to create stable
                reliable, and cut above automated test scripts faster than ever!
                Speed up test executions and achieve hassle-free maintenance of
                your automated tests.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AssetInnerBanner;

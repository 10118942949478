import React from "react";

const homefaqData = [
    
  {
    title: "Which platforms can be tested with FireFlink?",
    content: "FireFlink supports automation for various types of applications, such as Web, Mobile, Web and Mobile, APIs, and Enterprise applications like Salesforce and MS Dynamics. It does not require any additional plugins or frameworks.",
  },

  {
    title: "Does FireFlink require programming knowledge to automate scripts?",
    content: "No, FireFlink does not require any programming language knowledge. It employs a user-friendly, pre-defined Natural Language Processing (NLP) system that develops automation scripts in plain English.",
  },
  {
    title: "Can FireFlink be integrated with other platforms?",
    content: "Yes, FireFlink supports integration with various platforms, including cloud platforms like BrowserStack, SauceLabs, and Lambda Test, CI/CD tools like Jenkins and GitHub, project management tools like Jira and GitLab, and collaboration tools like Slack.",
  } , 
  {
    title:"What are the differences between FireFlink Cloud and On-premise Enterprise licenses?",
    content:"FireFlink Cloud stores data on the cloud network and offers both a free (Cloud Basic) and a paid (Cloud Professional) license. On the other hand, the On-premise Enterprise license involves setting up FireFlink within a customer's private network."
  },
  {
    title:" What if I can not find an appropriate NLP in FireFlink for my test case?",
    content:" FireFlink includes over 700 pre-defined NLPs that cover the majority of test cases. If none of these meet your requirements, you can use the \"Customize NLPs\" feature to create your own."
  },
  // Add more FAQ items as needed
];

const homeadditionalQuestion = [

  {
    title:"What is the FireFlink Client?",
    content:"FireFlink Client is a lightweight software that must be installed on your local machine to act as a bridge between the FireFlink server and your machine during test script execution."
  },
  {
    title:"Is the FireFlink Client compatible with all operating systems?",
    content:"Yes, the FireFlink Client is compatible with Windows, Mac, and Linux."
  },
  {
    title:"How does FireFlink identify UI elements for script development?",
    content:"FireFlink uses the built-in \"FireFlink Finder\" tool in the FireFlink Client to record UI elements with a single click."
  },
  {
    title:"Can I run test scripts on a machine where the scripts were not created?",
    content:"You can run test scripts on any machine that has the FireFlink Client installed."
  }
]


  

export { homefaqData , homeadditionalQuestion };



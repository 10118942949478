import React, { useState, useEffect } from "react";
import axios from "axios";
import AssetsRating from "./assets_ratings";

const ElementsDescription = (props) => {
  console.log("ElementsDescription",props)
  const [data,setdata]=useState(props?.details);
  const[benefits,setBenefits]=useState(props?.benefits)
  
 
    return(
      <section className="most_used_assets pb_30">
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-7">
            <div className="d-flex mt_40">
              <div className="excel_detail">
                <img
                  src={require("../../img/assets/excel-detail.svg").default}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <h3 className="asset_detail_head">{data?.title}</h3>
                <AssetsRating rating={data?.rating} />
                {/* <span className="fa fa-star asset_rating mt-2"></span> */}
                <h4 className="number_of_download mt-3">
                  {data?.downloads}{" "}
                  <span className="number_of_download_txt">Downloaded</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="ml-40">
            <p className="asset_detail_para  mt-3 mr-4">
              {data?.description}
            </p>
          </div>
        </div>
          <div className="excel mt_40 pb_30">
                  <h3 className="benefit_head text-center prodcolor mt-3">
                    Top Benefit
                  </h3>
                  <div className="asset_head_line m_auto"></div>

                  <div className="row">
                    <div className="col-lg-6">
                      {/* <div className="asset_benefit d-flex mt-3 pt-2 pl-3"> */}
                      {benefits?.map((benefit) => {
                        return (
                          <div className="asset_benefit d-flex mt-3 pt-2 pl-3">
                            <div className="benefit_star mr-2 fa fa-star"></div>
                            <h4 className="benefit_txt">{benefit}</h4>
                          </div>
                        );
                      })}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
       
      </div>
    </section>
   
    )
  }


export default ElementsDescription;

import React, { useEffect } from "react";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import enterpriseassets from "../../img/assets/enterprise-assets.png";
import testassets from "../../img/assets/test-assets.png";
import platformassets from "../../img/assets/platform-assets.png";
import domainspecific from "../../img/assets/domain-specific.png";

const AssetsProduct = () => {
  const history = useHistory();
  useEffect(() => {
    const platformAssets = document.getElementById("platform_assets");
    const domainAssets = document.getElementById("domain_assets");
    const templateStore = document.getElementById("template_store");
    if (history.location.hash === "#platform_assets") {
      // 👇 Will scroll smoothly to the top of the next section
      platformAssets.scrollIntoView({ behavior: "smooth" });
    } else if (history.location.hash === "#domain_assets") {
      domainAssets.scrollIntoView({ behavior: "smooth" });
    } else if (history.location.hash === "#template_store") {
      templateStore.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <section className="productoffer mt_300">
      <div className="container">
        <div className="row mb_40">
          <div className="col-lg-6 mt_30">
            <Slide left>
              <h3 className="mt_40">
                Enterprise applications Testing challenges
              </h3>

              <p>
                Due to the shorter sprint cycles introduced by extreme agile,
                the testing team has had little to no time to perform regression
                testing. Because of the frequency of releases, full regression
                testing is now mostly a thing of the past.
              </p>
            </Slide>
          </div>

          <div className="col-lg-6">
            <Slide right>
              <img
                className="prodimg"
                src={enterpriseassets}
                alt="enterprise assets"
              />
            </Slide>
          </div>
        </div>

        <div className="row mt_40 mb_40">
          <div className="col-lg-6 ">
            <Slide left>
              <img
                className="fade-right prodimg"
                src={testassets}
                alt="test assets"
              />
            </Slide>
          </div>

          <div className="col-lg-6 mt_30">
            <Slide right>
              <h3>What are test assets?</h3>

              <p>
                There is an urgent need for a rapid testing solution for the
                increasingly complex applications that are being created. The
                entire testing process can be sped up with ready-to-use assets
                like UI elements, scenarios for testing the domain-specific
                common workflows, and so on, allowing the teams to begin manual
                and automated testing on day one.
              </p>
            </Slide>
            <div className="mb_50" id="platform_assets"></div>
          </div>

          {/* <div className="mb_50"  >.</div> */}
        </div>
        <div className="row mt_40 mb_40">
          <div className="col-lg-6 mt_30">
            <Slide left>
              <h3>Platform Test Assets</h3>

              <p>
                Conventional test automation tools have always struggled to
                fully automate the testing of enterprise platform applications
                like Salesforce, Microsoft Dynamics 365.
              </p>
              <ul>
                <div className="d-flex">
                  <div className="">
                    <img src={require("../../img/assets/tick.svg").default} alt=""/>
                  </div>

                  <li>
                    {" "}
                    For larger enterprise applications, for instance the task of
                    creating a UI elements repository is extremely time
                    consuming due to the large number of complex DOM elements
                    involved.
                  </li>
                </div>
                <div className="d-flex" id="domain_assets">
                  <div className="">
                    <img src={require("../../img/assets/tick.svg").default} alt=""/>
                  </div>

                  <li>
                    {" "}
                    FireFlink's assets give your team quick and easy access to
                    the most frequently used UI elements and workflows, allowing
                    you to jump right into script development.
                  </li>
                </div>
              </ul>
            </Slide>
          </div>

          <div className="col-lg-6">
            <Slide right>
              <img
                className="prodimg"
                src={platformassets}
                alt="platform assets"
              />
            </Slide>
          </div>
        </div>
        <div className="row mt_40 mb_40">
          <div className="col-lg-6">
            <Slide left>
              <img
                className="prodimg"
                src={domainspecific}
                alt="domain specific"
              />=
              
            </Slide>
          </div>

          <div className="col-lg-6 mt_30">
            <Slide right>
              <h3>Domain Test Assets</h3>

              <p>
                We provide you with access to pre-built test cases for any
                industry, including but not limited to Banking, Insurance,
                E-commerce, Customer Relationship Management and many more. The
                amount of time spent by the testing team on generating the most
                frequent domain-based scenarios is drastically reduced.
              </p>
              <ul>
                <div className="d-flex">
                  <div className="">
                    <img src={require("../../img/assets/tick.svg").default} alt=""/>
                  </div>

                  <li>
                    {" "}
                    Save your precious time by acquiring massive test case
                    storehouse for your business use cases
                  </li>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={require("../../img/assets/tick.svg").default} alt=""/>
                  </div>

                  <li>
                    {" "}
                    Off-the-shelf test cases for common business flows in your
                    application
                  </li>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={require("../../img/assets/tick.svg").default} alt=""/>
                  </div>

                  <li>
                    {" "}
                    Find all the types of use cases from happy flows to edge
                    cases
                  </li>
                </div>
                <div className="d-flex">
                  <div className="">
                    <img src={require("../../img/assets/tick.svg").default} alt=""/>
                  </div>

                  <li>
                    {" "}
                    Obtain predefined complex domain-wise test cases to make the
                    most of QA resource time and enhance overall coverage
                  </li>
                </div>
                <div className="d-flex" id="template_store">
                  <div className="">
                    <img src={require("../../img/assets/tick.svg").default} alt=""/>
                  </div>

                  <li>
                    {" "}
                    Our automation acceleration team can lend a hand in
                    incorporating application-specific scenarios into the test
                    pack, thereby facilitating test productivity from the
                    get-go.
                  </li>
                </div>
              </ul>
            </Slide>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AssetsProduct;

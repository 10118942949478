import React, { useState, useEffect } from "react";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import { bottom } from "@popperjs/core";
import {
  getNLPCategoriesbyID,
  getNLPCategoriesDetailsByTitle,
  getNLPScenarios,
  getNLPQustionAndAnswers,
  getNLPList,
} from "../../api/api_services";
import { useHistory } from "react-router-dom";
import WhenToUse from "./WhenToUse";
import AssetsRating from "./assets_ratings";
import { Rating } from "@mui/material";
import {
  homeadditionalQuestion,
  homefaqData,
} from "../../components/HomepageFaq";
import CommonFaq from "../../components/Common/Faq";
import Skeleton from "react-loading-skeleton";
import NodataFound from "./nodataFound";

const ExcelDetail = () => {
  const [Nlpdetails, setNlpDetails] = useState({});
  const [nlpList, setNlpList] = useState({});
  const [ScenariosList, setScenariosList] = useState([]);
  const [QustionAndAnswer, setQuestionAndAnswer] = useState([]);
  let history = useHistory();
  const [dataloading, setdataLoading] = useState(true);
  const search = window.location.search;
  const [nlpdatalist, setNLPdataList] = useState([]);
  const [nlplistdata, setnlplistdata] = useState([]);
  const [expandNlpList,setExpandMoreNlpList]=useState(false);
  const[searchData,setSearchData]=useState("")

  useEffect(() => {
    getNLPCategoriesDetails();
  }, []);
  const getNLPCategoriesDetails = () => {
    const id = new URLSearchParams(search).get("id");
    const title = new URLSearchParams(search).get("title");
    getNLPCategoriesbyID(id)
      .then((response) => {
        if (
          response?.data?.responseCode === 200 &&
          response?.data?.message === "SUCCESS"
        ) {
          setNlpDetails(response?.data?.responseObject);
          getNLPDetails(title, response?.data?.responseObject?.typeId);
        } else {
          setNlpDetails({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNLPDetails = async (title, typeId) => {
    let response = await getNLPCategoriesDetailsByTitle(title, typeId);
    console.log("response 123", response);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      setNlpList(response?.data?.responseObject[0]);
      getListOfNLP(title, typeId);
      // getScenarios(title)
    } else {
      setNlpList({});
      setdataLoading(false)
    }
  };
  const getListOfNLP = async (title, typeId) => {
    let response = await getNLPList(title, typeId);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      setnlplistdata(response?.data?.responseObject);
      let tempdata = response?.data?.responseObject.slice(0, 10);
      setNLPdataList(tempdata);
      getScenarios(title);
    } else {
      setNLPdataList([]);
      setdataLoading(false);
    }
  };

  const getScenarios = async (title) => {
    let size = 5;
    let response = await getNLPScenarios(title, size);
    console.log("getNLPScenarios", response);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      setScenariosList(response?.data?.responseObject);
      getQuestionAndAnswer(title);
    } else {
      setScenariosList([]);
      setdataLoading(false)
    }
  };

  const getQuestionAndAnswer = async (title) => {
    let size = 10;
    let response = await getNLPQustionAndAnswers(title, size);
    console.log("getNLPScenarios", response);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      setQuestionAndAnswer(response?.data?.responseObject);
      setdataLoading(false);
    } else {
      setQuestionAndAnswer([]);
      setdataLoading(false);
    }
  };
  const getMorenlpList = (state) => {
    if(state){
      setExpandMoreNlpList(false);
      let temp=nlplistdata?.slice(0, 10)
      setNLPdataList(temp);
    }else{
      setExpandMoreNlpList(true);
      setNLPdataList(nlplistdata);
    }
    
  };

  const handleSearch=(e)=>{
    setSearchData(e.target.value)
    if(e.target.value.length===0){
      setSearchData(e.target.value)
      let tempdata =nlplistdata.slice(0, 10);
      setNLPdataList(tempdata);
  }
  }
  const handleEnterKeyPress=(event)=>{

    if (event.key === 'Enter') {
      if(searchData.length===0){
        let tempdata = nlplistdata.slice(0, 10);
        setNLPdataList(tempdata);
      }else{
       
        handleSearchdata(searchData);
        
      }
    }
  };

  const handleSearchdata = (value) => {
    let searchValue = value;
    if (searchValue) {
      let tempSimilarArray = [];
      nlplistdata.map((proVal, index) => {
        let name = proVal?.nlpName;
        let description = proVal?.shortDescription;

        let similarExistname = name?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistDescription = description?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );

        if (similarExistname || similarExistDescription) {
          tempSimilarArray.push(proVal);
          setNLPdataList(tempSimilarArray);
        }
      });
      if(tempSimilarArray?.length===0){
        setNLPdataList([])
      }
    } else {
      let tempdata = nlplistdata.slice(0, 10);
      setNLPdataList(tempdata);
    }
  };

  return (
    <section className="most_used_assets pb_30">
      {
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sce_btn mt_40">
                  <div className="d-flex ml-2 mt-3">
                    {dataloading ? (
                      <>
                        <Skeleton width={113} height={113} borderRadius={100} />
                      </>
                    ) : (
                      <>
                        <div className="excel_detail">
                          {Nlpdetails.title === "Excel" ? (
                            <>
                              <img src={Nlpdetails?.iconS3Path} alt="" />
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  require("../../img/assets/excel-detail.svg")
                                    .default
                                }
                                alt=""
                              />
                            </>
                          )}
                        </div>
                      </>
                    )}

                    <div className="ml-3">
                      <h3 className="asset_detail_head">
                        {dataloading ? (
                          <Skeleton width={200} />
                        ) : (
                          Nlpdetails.title
                        )}
                      </h3>
                      {/* {
                    getRating(Nlpdetails?.rating)
                  } */}
                      {dataloading ? (
                        <Skeleton width={150} />
                      ) : (
                        <AssetsRating rating={Nlpdetails?.rating} />
                      )}

                      {dataloading ? (
                        <Skeleton width={100} />
                      ) : (
                        <h4 className="number_of_download mt-3">
                          {Nlpdetails?.downloads}{" "}
                          <span className="number_of_download_txt">
                            Downloaded
                          </span>
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
                {dataloading ? (
                  <Skeleton width={1100} />
                ) : (
                  <p className="asset_detail_para ml-4 mt-3 mr-4">
                    {Nlpdetails?.description}
                  </p>
                )}
              </div>
            </div>
            {dataloading ? (
              <>
                <Skeleton width={1100} height={200} />
              </>
            ) : (
              <>
                <div className="excel mt_40 pb_30">
                  <h3 className="benefit_head text-center prodcolor mt-3">
                    Top Benefit
                  </h3>
                  <div className="asset_head_line m_auto"></div>

                  <div className="row">
                    <div className="col-lg-6">
                      {/* <div className="asset_benefit d-flex mt-3 pt-2 pl-3"> */}
                      {nlpList?.benefits?.map((benefit) => {
                        return (
                          <div className="asset_benefit d-flex mt-3 pt-2 pl-3">
                            <div className="benefit_star mr-2 fa fa-star"></div>
                            <h4 className="benefit_txt">{benefit}</h4>
                          </div>
                        );
                      })}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row">
              <div className="col-lg-6">
                <h2 className="pt-50 extract_head upper_case ml-15">Nlp’s</h2>
              </div>
              <div className="col-lg-6 pt-50">
                <div className="fr">
                  <TextField
                    //label="With normal TextField"
                    placeholder="Search"
                    variant="standard"
                    // onChange={(e) => {
                    //   handleSearch(e);
                    // }}
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    onKeyDown={handleEnterKeyPress}
                    autoComplete="off"
                    sx={{
                      width: "260px",
                      height: "26px",
                      border: "1px solid #DB7221",
                      background: "#DB722126",
                      borderRadius: "26px",
                      "& .MuiInput-underline:before": { display: "none" },
                      "& .MuiInput-underline:after": { display: "none" },
                      "& input": { padding: "0px", marginLeft: "4px" },
                      padding: "0px",
                      paddingLeft: "5px",
                      fontSize: "8px",
                    }}
                    InputProps={{
                      fontSize: "30px",
                      endAdornment: (
                        <InputAdornment className="assest_type_search_txt">
                          <IconButton className="nlp_search_btn">
                            <SearchIcon className="assest_type_search_icon"  onClick={()=>{handleSearchdata(searchData)}}/>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              
              <div className="asset_head_line ml-3"></div>
              <div className="asset_list_line_grey w100"></div>
            </div>
            {
              nlpdatalist.length>0?( <div className="row">
              {nlpdatalist?.map((nlp) => {
                return (
                  <div className="col-lg-6 ">
                    <div className="extract_box ml-15 mt-4 d-flex">
                      <div className="col-lg-10">
                        <h3 className="f_600 f_size_12 f_p mt-3">
                          {nlp?.nlpName}
                        </h3>
                        <p className="f_normal f_size_10 f_r mt-2 mb-2">
                          {nlp?.shortDescription}{" "}
                        </p>
                      </div>
                      <div
                        className="col-lg-2"
                        onClick={() => {
                          history.push(`/nlp-description?id=${nlp?._id}`);
                        }}
                      >
                        <button className="details_btn mt-4">Details</button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>):(
              <div className="nodatafoun-container mt_15">
                <NodataFound/>
              </div>
            )
            }
           
            {
              nlpdatalist?.length>10 && (<div className="text-center mt_30">
              <button className="explore_more" onClick={()=>{getMorenlpList(expandNlpList)}}>
                {expandNlpList?"View less Nlp's":"Explore More Nlp’s"}
              </button>
            </div>)
            }
            
            <WhenToUse ScenariosList={ScenariosList} />
            <CommonFaq
              faqData={homefaqData}
              additionalQuestion={homeadditionalQuestion}
              data={QustionAndAnswer}
            />
          </div>
        </>
      }
    </section>
  );
};

export default ExcelDetail;

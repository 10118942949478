import React, { useEffect, useState } from "react";
import assets from "../../img/assets/assets.png";
import { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";
import { right } from "@popperjs/core";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";

const FireAssetsBanner = (props) => {
  const [category, setCategory] = useState("All Resource");
  const [searchedValue,setSearchedValue]=useState("");
  const [assetTypeID,setAssetsTypeID]=useState("")

  

  const handleChange=(e)=>{
    setCategory(e.target.value)
    if(e.target.value!=="All Resource"){
      let typeId;
    let assetstype=localStorage.hasOwnProperty("assets-type")&&JSON.parse(localStorage.getItem("assets-type"));
    assetstype.map((assets)=>{
      if(assets.type===e.target.value){
        typeId=assets.id
        setAssetsTypeID(typeId)
      }
    })
    }
    
  }
  const handleSeach=(e)=>{
   
    setSearchedValue(e.target.value);
    if(e.target.value.length===0){
      props?.handleSearch("","",assetTypeID)
    }
    
    
    
  }
  const handleEnterKeyPress=(event)=>{
    if (event.key === 'Enter') {
      if(searchedValue.length===0){
        props?.handleSearch("","",assetTypeID)
      }else{
       
          props?.handleSearch(category,searchedValue,assetTypeID)
        
      }
    }
  };
 
 
  return (
    <section className="assetsbanner">
      <div className="container">
        <div className="row">
          <div className="col-lg-12  align-items-center">
            <div className="home_banner_content">
              <h1 className="wow fadeInUp" data-wow-delay="0.3s">
                EXTREME TEST <span>ASSETS</span>
              </h1>
              <p className=" mb-30 wow fadeInUp" data-wow-delay="0.4s">
                The world's one and only marketplace for all the software
                testing assets. <br /> Access to a diverse set of assets across
                a variety of enterprise applications and domains.
              </p>
              {/* <p className=" mb-30 wow fadeInUp" data-wow-delay="0.4s">
                {props?.data?.homeScreen?.descriptionOne}
                <br /> {props?.data?.homeScreen?.descriptionTwo}
              </p> */}

              <div className="feat_content ml-90 d-flex home_content_res img_center outer_d_flex">
                <div className="d-flex">
                  <img
                    src={require("../../img/home/round-tick.svg").default}
                    alt="high speed"
                  />
                  <p>Kickstart testing instantly on any of your applications</p>
                </div>
                <div className="d-flex">
                  <img
                    src={require("../../img/home/round-tick.svg").default}
                    alt="automation"
                  />
                  <p>Ready to use domain specific test assets</p>
                </div>
              </div>
              <div className="feat_content ml-30 d-flex home_content_res img_center outer_d_flex">
                <div className="d-flex">
                  <img
                    src={require("../../img/home/round-tick.svg").default}
                    alt="no coding"
                  />
                  <p>
                    Assets on enterprise platforms such as MS Dynamics 365,
                    Salesforce, and Workday
                  </p>
                </div>
                <div className="d-flex">
                  <img
                    src={require("../../img/home/round-tick.svg").default}
                    alt="no coding"
                  />
                  <p>Custom templates store for reports and analytics</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex text-center asset_search">
          <div className="col-lg-4">
            <div className="select_asset">
              <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={category}
          values={category}
          onChange={handleChange}
          size="small"
          
          sx={{
            fontSize: "18px",
                  fontWeight: "600",
                  color: "#565872",
                  fontFamily: "Poppins, SemiBold",
                  marginTop: "8px",
            width:'100%',
          boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                  "& fieldset": {
                    border: "none",
                  },
                  '.MuiOutlinedInput-notchedOutline': { border: 'none'}
              }}
        
          // sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },width:'100%' }}
        >
          <MenuItem value="All Resource">All Resource</MenuItem>
          <MenuItem value="Nlps">NLPs</MenuItem>
          <MenuItem value="Elements">Elements</MenuItem>
          <MenuItem value="StepGroup">StepGroup</MenuItem>
        </Select>
            </div>
          </div>
          <div className="drod_bar"></div>
          <div className="col-lg-7 text-left">
            <div className="search_asset_field">
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search for Assets"
                value={searchedValue}
                sx={{
                  
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#565872",
                  fontFamily: "Poppins, SemiBold",
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                }}
                InputProps={{
                  style: {color: '#565872' , fontSize: '18px'},
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={(e)=>{handleSeach(e)}}
                onKeyDown={handleEnterKeyPress}
              />
            </div>
          </div>
          <div className="col-lg-1" >
            <div className="asset_search_btn">
              <button onClick={()=>{props?.handleSearch(category,searchedValue,assetTypeID)}}> 
                <i className="ti-search asset_search_icon"></i>
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <div className="sce_btn">
        <a
          href="/schedule-demo"
          className="home_banner_btn_hover home_banner_btn"
        >
          Schedule a Demo
        </a>
      </div>
      <div className="text-center">
        <img className="cloud_res_img" src={assets} alt="cloud response" />
      </div>
    </section>
  );
};
export default FireAssetsBanner;

import axios from "axios";
// import {logger} from "../components/Common/Constants/app-constanta"

const managementBaseUrl = process.env.REACT_APP_MANAGEMENT_BASE_URL;

const instance = axios.create({});

instance.interceptors.request.use(function(config) {
  //   const project = localStorage.getItem("selected-project");
  //   const gitProject = localStorage.getItem("selected-Git-project");
  const user = localStorage.getItem("login-user");
  const userSession = user ? JSON.parse(user) : {};
  //   const selectedProject = project ? JSON.parse(project) : {};
  //   const selectedGitProject = gitProject ? JSON.parse(gitProject) : {};
  //   const configRequest = JSON.parse(localStorage.getItem("configReq"));
  //   const configPutRequest = JSON.parse(localStorage.getItem("configPutReq"));
  //   const ResultGetRequest = JSON.parse(localStorage.getItem("resultGetReq"));
  //   const ResultPutRequest = JSON.parse(localStorage.getItem("resultPutReq"));

  let token = null;
  if (userSession) {
    token = userSession.access_token;
  }
  //   const passProjectId =config.passProjectId === undefined ? true : config.passProjectId;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  //   if (!config.headers?.projectId && passProjectId) {
  //     if (
  //       !configRequest &&
  //       !configPutRequest &&
  //       !ResultGetRequest &&
  //       !ResultPutRequest
  //     ) {
  //       config.headers["projectId"] = selectedProject
  //         ? selectedProject.id
  //         : "PJT6549";
  //         config.headers["projectType"] = selectedProject
  //         ? selectedProject.type
  //         : undefined;
  //       if (config.headers["projectId"] === undefined) {
  //         config.headers["projectId"] = "";
  //       }
  //       if (selectedGitProject.id && !selectedProject.id) {
  //         config.headers["projectId"] = selectedGitProject
  //           ? selectedGitProject.id
  //           : "PJT6549";
  //       }
  //     }
  //   }
  // if config type is blob, should not add the Accept, Content-Type headers in request
  if (config.responseType === "blob") {
    return config;
  }

  if (config.type === "formdata") {
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  }

  config.headers["Accept"] = "application/json";
  config.headers["Content-Type"] = "application/json";
  return config;
});

const updateTokenData = (response) => {
  if (response.status === 200 && response?.data?.access_token) {
    localStorage.setItem("test-optimize-user", JSON.stringify(response.data));
    // logger("TokenData Updated!", response);
  }
};

// const refreshTokenExpired = (error) => {
//   logger("Refresh Token Expired! ", error);
//   localStorage.clear();
//   window.location.replace("/signin");
// };
// createAxiosResponseInterceptor();

// function createAxiosResponseInterceptor() {
//  instance.interceptors.response.use(
//     (response) => {
//           const isTokenExpired = localStorage.getItem("refreshToken");
//           if (isTokenExpired) {
//             localStorage.removeItem("refreshToken");
//             logger("Token Expired!", response);
//           }
//       return response},
//     (error) => {
//       logger("Interceptor Error =>", error);

//       if (error.response.status === 401) {
//         logger("Refresh Token API called!")
//         localStorage.setItem("refreshToken", getRefreshToken());
//         return axios
//           .post(`${managementBaseUrl}/optimize/v1/public/user/refresh`, {
//             emailId: getUserEmailId(),
//             refreshToken: getRefreshToken(),
//             currentLicenseId: getCurrentLicenseId()
//           })
//           .then((response) => {
//             updateTokenData(response);
//             const newAccessToken= response?.data?.access_token
//             error.response.config.headers["Authorization"] = "Bearer " + newAccessToken;
//             return axios(error.response.config);
//           })
//           .catch((error2) => {
//             refreshTokenExpired(error2);
//             return Promise.reject(error2);
//           });
//       }
//       if (error.response.status !== 401) {
//         return Promise.reject(error);
//       }
//     }
//   );
// }

export default instance;

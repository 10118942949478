import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {getNLPlistBySearch} from "../../api/api_services"
import NodataFound from "./nodataFound";

const AssetResultCategory = (props) => {
  console.log("AssetResultCategory",props)
  let [nlplist,setnlplist] = useState();
  let [nlpdatalist,setnlpdatalist]=useState(props?.nlplist)
  let [searchkey,setSearchKey]=useState(props?.searchkey)
  let [ActiveTab,setActiveTab]=useState("all")
  let history = useHistory();
  let [ExpandMore,setExpandMore]=useState(false)
  useEffect(()=>{
    handlenlplist(props?.nlplist)

  },[props])
  const handlenlplist=(data)=>{
    let temparray=data?.slice(0,10);
    setnlplist(temparray)
  }
  const getmorenlp=(state)=>{
    if(state){
      setExpandMore(false);
      let temparray=nlpdatalist?.slice(0,10);
    setnlplist(temparray)
    }else{
      setExpandMore(true)
    setnlplist(nlpdatalist)
    }
    
  }
  const getAllnlp = async (searchvalue,key) => {
    let payload = key==="all"?{
      key: searchvalue
    }:{
      key: searchvalue,
      assetsTags:[key]
    }
    let response = await getNLPlistBySearch(payload);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      setnlpdatalist(response?.data?.responseObject)
      let temarray=response?.data?.responseObject.slice(0,10)
      setnlplist(temarray);
    }
  };
 
 const handlefilter=(key)=>{
  switch (key) {
    case "all":
      setActiveTab("all")
      getAllnlp(searchkey,"all")
      break;
      case "Web":
        setActiveTab("Web")
        getAllnlp(searchkey,"Web")
      break;
      case "Web_And_Mobile":
        setActiveTab("Web_And_Mobile")
        getAllnlp(searchkey,"Web_And_Mobile")
      break;
      case "Android":
        setActiveTab("Android")
        getAllnlp(searchkey,"Android")
      break;
      case "iOS":
        setActiveTab("iOS")
        getAllnlp(searchkey,"iOS")
      break;
    default:
      setActiveTab("all")
      getAllnlp(searchkey,"all")
      break;
  }
 }
  return (
    <section className="pb_30">
      {
        props?.nlplist && ( <div className="container">
        <div className="row">
        <div className="col-lg-6">
           
          </div>
          <div className="col-lg-6">
            <div className="item_type pt-50">
              <div className={ActiveTab==="all"?"items_type_asset_active":"items_type_asset"} onClick={()=>{handlefilter("all")}}>All items</div>
              <div className={ActiveTab==="Web"?"items_type_asset_active":"items_type_asset"} onClick={()=>{handlefilter("Web")}}>Web</div>
              <div className={ActiveTab==="Web_And_Mobile"?"items_type_asset_active":"items_type_asset"} onClick={()=>{handlefilter("Web_And_Mobile")}}>Web & Mobile</div>
              <div className={ActiveTab==="Android"?"items_type_asset_active":"items_type_asset"} onClick={()=>{handlefilter("Android")}}>Android</div>
              <div className={ActiveTab==="iOS"?"items_type_asset_active":"items_type_asset"} onClick={()=>{handlefilter("iOS")}}>iOS</div>
            </div>
          </div>
        </div>
        {
          nlplist?.length > 0?( <div className="row">
          {
            nlplist?.map((data)=>{
              return(
                <div className="col-lg-6 ">
            <div className="extract_box ml-15 mt-4 d-flex">
              <div className="col-lg-10">
                <h3 className="f_600 f_size_12 f_p mt-3">{data?.nlpName}</h3>
                <p className="f_normal f_size_10 f_r mt-2 mb-2">{data?.shortDescription}</p>
              </div>
              <div className="col-lg-2">
                <button className="details_btn mt-4"  onClick={() => {
                          history.push(`/nlp-description?id=${data?._id}`);
                        }}>Details</button>
              </div>
            </div>
           
          </div>
              )
            })
          }
         
        </div>):( <div className="nodatafoun-container">
        <NodataFound/>
          </div>)
        }
       
        {
          nlplist?.length >10 && ( <div className="text-center mt_30" onClick={()=>{getmorenlp(ExpandMore)}}>
          <button className="explore_more">{ExpandMore?"View less NLP,s":"Explore More NLP’s"}</button>
          </div>)
        }
       
      </div>)
      }
     
    </section>
  );
};

export default AssetResultCategory;

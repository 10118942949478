export const alertLicenseExpiredUser = (billingCycle, remainingDays) => {
  if (billingCycle === "Yearly" && remainingDays <= 15) {
    return true;
  } else if (billingCycle !== "Yearly" && remainingDays <= 7) {
    return true;
  } else {
    return false;
  }
};
export const verifyLicenseStatus = (data) => {
  if (
    data.userStatus !== "DISABLED" &&
    data.access_token &&
    data.licenseStatus !== "EXPIRED" &&
    !alertLicenseExpiredUser(data.billingCycle, data.expires_in)
  ) {
    return true;
  } else if (
    data.access_token &&
    (data.licenseStatus === "EXPIRED" || data.userStatus === "DISABLED")
  ) {
    return false;
  } else if (
    data.access_token &&
    data.licenseStatus !== "EXPIRED" &&
    alertLicenseExpiredUser(data.billingCycle, data.expires_in)
  ) {
    return false;
  }
};

import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import * as serviceWorker from "./serviceWorker";

import "./assets/themify-icon/themify-icons.css";
import "./assets/simple-line-icon/simple-line-icons.css";
import "./assets/font-awesome/css/all.css";
import "./assets/elagent/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.css";
import "./assets/responsive.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js";
import "react-phone-input-2/lib/style.css";

import "react-notifications/lib/notifications.css";

const loader = document.querySelector("#preloader");

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide");

const hideLoader = () => loader.classList.add("loader--hide");

const renderApp = () => {
  const rootElement = document.getElementById("root");
  if (rootElement.hasChildNodes()) {
    hydrate(
      <HelmetProvider>
        <App hideLoader={hideLoader} showLoader={showLoader} />
      </HelmetProvider>,
      rootElement
    );
  } else {
    render(
      <HelmetProvider>
        <App hideLoader={hideLoader} showLoader={showLoader} />
      </HelmetProvider>,
      rootElement
    );
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

// Call the renderApp function after a delay of 1000ms (1 second)
setTimeout(renderApp, 1000);

import React, { useEffect, useState } from "react";

const CommonFaq = (props) => {
  console.log("CommonFaq",props)
 const[QuestionAndAnswer,setQuestionAndAnswer]=useState();
 useEffect(()=>{
  handelCommonFaq(props?.data);

 },[props])
  const handelCommonFaq =(data)=>{
    setQuestionAndAnswer(data)
  }
  const [showMore, setShowMore] = useState(false);
  const showMoreData = () => {
    setShowMore(!showMore);
  };

  return (
    <section className="faq_area platformfaq_back bg_color sec_pad">
      <div className="container">
        <h2>Frequently Asked Questions</h2>
        <div className="sce_btn">
                        <div className="head_line"></div>
                        </div>
        <div className="row">
          <div className="col-lg-12">
          <div className="platformfaq">
            <div className="tab-content faq_content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="purchas"
                role="tabpanel"
                aria-labelledby="purchas-tab"
              >
                <div id="accordion">
                  {QuestionAndAnswer?.map((item, index) => {
                    if(index < 5){
                      return (
                        <div className="card" key={index}>
                        <div className="card-header" id={`heading${index}`}>
                          <h5 className="mb-0">
                            <button
                              className={`btn btn-link ${
                                index === 0 ? "" : "collapsed"
                              }`}
                              data-toggle="collapse"
                              data-target={`#collapse${index}`}
                              aria-expanded={index === 0 ? "true" : "false"}
                              aria-controls={`collapse${index}`}
                            >
                              {item.question}
                              <i className="ti-angle-down"></i>
                              <i className="ti-minus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id={`collapse${index}`}
                          className={`collapse ${index === 0 ? "show" : ""}`}
                          aria-labelledby={`heading${index}`}
                          data-parent="#accordion"
                        >
                          <div className="card-body">{item.answer}</div>
                        </div>
                      </div>
                      )
                    }
                   
})}
                  {showMore && (
                    <div className="card">
                      {QuestionAndAnswer?.map((item, index) => {
                        if(index >=5){
                          return(
                            <div className="card" key={index}>
                            <div className="card-header" id="headingmore">
                              <h5 className="mb-0">
                                <button
                                  className="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target={`#collapsemore${index}`}
                                  aria-expanded={index === 0 ? "false" : "true"}
                                  aria-controls={`collapsemore${index}`}
                                  // data-toggle="collapse"
                                  // data-target="#collapsemore"
                                  // aria-expanded="false"
                                  // aria-controls="collapsemore"
                                >
                                  {item.question}
                                  <i className="ti-angle-down"></i>
                                  <i className="ti-minus"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                                id={`collapsemore${index}`}
                                className={`collapse ${index}`}
                                // className={`collapse ${index === 0 ? "show" : ""}`}
                                aria-labelledby={`headingmore${index}`}
                                data-parent="#accordion"
                              // id="collapsemore"
                              // className="collapse"
                              // aria-labelledby="headingmore"
                              // data-parent="#accordion"
                            >
                              <div className="card-body">{item.answer}</div>
                            </div>
                          </div>
                          )
                        }
                       
})}
                    </div>
                  )}
                </div>
                {
                  QuestionAndAnswer?.length>5 && ( <button className="show_more_btn" onClick={showMoreData}>
                  {!showMore ? "Show More" : "Show less"}
                </button>)
                }
               
              </div>
            </div>
            <p>
              Looking for something else ? <a className="prodcolor" href="/contactus">Contact us</a>
            </p>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonFaq;

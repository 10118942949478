import React, { useState } from "react";
import {Link} from 'react-router-dom';

const PlatformFaq = () => {
  const [showMore, setShowMore] = useState(false);
  const showMoreData = () => {
    setShowMore(!showMore);
  };

  return (
    <section className="faq_area platformfaq_back bg_color sec_pad">
      <div className="container">
        <h2>Frequently Asked Questions</h2>
        <div className="sce_btn">
                        <div className="head_line"></div>
                        </div>
        <div className="row">
          <div className="platformfaq">
            <div className="tab-content faq_content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="purchas"
                role="tabpanel"
                aria-labelledby="purchas-tab"
              >
                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">How to purchase</h3> */}
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Is Fireflink 100% scriptless? What if no NLPs exist
                          for my project-specific actions?
                          <i className="ti-angle-down"></i>
                          <i className="ti-minus"></i>
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <ul>
                          <li>
                            The majority of the time, you do not need to create
                            any programmes; instead, you may utilise our
                            existing NLPs. However, if you have project-specific
                            requirements, you can construct your own NLPs, in
                            which case you may need to write programmes.
                          </li>
                          <li>
                            Eg:Sometimes you require Data simulation or Mocking
                            Solutions which are very specific to your project .
                          </li>
                          <li>
                            In a standard automation situation, if you have 5
                            engineers, all 5 engineers must be excellent at
                            programming, however with FireFlink, you may only
                            need 1 engineer with fair programming expertise to
                            create customised NLPs.
                          </li>
                        </ul>
                        Custom NLPs can be developed with any language like
                        <ol>
                          <li>Java</li>
                          <li>Python</li>
                          <li>C#</li>
                          <li>JavaScript</li>
                          <li>Ruby</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Why are test development and test execution separated?
                          <i className="ti-angle-down"></i>
                          <i className="ti-minus"></i>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <ul>
                          <li>
                            The test automation process is divided into two
                            stages: test development and test execution.
                          </li>
                          <li>
                            Many tools exist today that attempt to integrate
                            these two phases into a single segment. However, in
                            a real-time project, this would be ineffective.
                          </li>
                          <li>
                            In test development as and when new requirements are
                            added, we keep developing new scripts/elements/test
                            data.
                          </li>
                          <li>
                            These new scripts should not impact the existing
                            suites which are used for live testing, unless it is
                            explicitly reviewed & published from Test
                            Development.
                          </li>
                          <li>
                            Each script that is developed in Test Development
                            Section can be added into multiple suites in the
                            Execution section
                          </li>
                          <li>
                            Test Development is exclusively meant for the
                            development and the maintenance of the test scripts
                            & executing it in sequence by checking if the script
                            is developed as per the given testcase.
                          </li>
                          <li>
                            But execution of these scripts is meant to happen in
                            the Test Execution section by forming suites which
                            can be configured to execute on different
                            environments of your application
                            (TestEnvironment/UAT/PreProd) through various
                            execution mechanisms such as in sequence/Parallel/in
                            a distributed way. The execution can either be
                            triggered manually or triggered from the CI/CD
                            pipeline or triggered through the inbuilt scheduler
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How do you handle waits/Synchronization issues during
                          automation?
                          <i className="ti-angle-down"></i>
                          <i className="ti-minus"></i>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        We support different types of wait in the form of simple
                        UI & through NLP’s
                        <ol>
                          <li>Hardcoded wait statements </li>
                          <li>Implicit wait</li>
                          <li>Explicit waits </li>
                          <li>Custom Waits </li>
                        </ol>
                        All the above waits can be configured at:
                        <ol>
                          <li>Step Level</li>
                          <li>Script level </li>
                          <li>Module Level</li>
                          <li>Entire Suite Level</li>
                        </ol>
                        All the above wait’s can be configured at
                        <ol>
                          <li>Test Development level</li>
                          <li>Test Execution level </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingfour">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapsefour"
                          aria-expanded="false"
                          aria-controls="collapsefour"
                        >
                          How do you validate actions?
                          <i className="ti-angle-down"></i>
                          <i className="ti-minus"></i>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapsefour"
                      className="collapse"
                      aria-labelledby="headingfour"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        FireFlink has NLPs to perform all kinds of
                        verifications/ to validate any actions.
                        <br />
                        <b>
                          For example, if you want to confirm the presence of an
                          element, use NLP - "Verify if *element* is Displayed."
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingfive">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapsefive"
                          aria-expanded="false"
                          aria-controls="collapsefive"
                        >
                          How do I read data from files?
                          <i className="ti-angle-down"></i>
                          <i className="ti-minus"></i>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapsefive"
                      className="collapse"
                      aria-labelledby="headingfive"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <ul>
                          <li>
                            FireFlink has NLP’s to read data from any type of
                            files like excel, PDF, JSON etc.
                          </li>
                          <li>
                            The file must be uploaded in the Test Data section
                            before accessing it in the script.
                          </li>
                        </ul>
                        <b>
                          For example, if you want to get data from an excel
                          sheet, you can use "Excel Get Cell Data NLP."
                        </b>
                      </div>
                    </div>
                  </div>
                  {showMore ? (
                    <>
                      <div className="card">
                        <div className="card-header" id="headingfive">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapsefive"
                              aria-expanded="false"
                              aria-controls="collapsefive"
                            >
                              Can a single Project/Script have multiple types of
                              steps?
                              <i className="ti-angle-down"></i>
                              <i className="ti-minus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapsefive"
                          className="collapse"
                          aria-labelledby="headingfive"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            In a single project you can build automation scripts
                            for
                            <ol>
                              <li>Web application</li>
                              <li>Mobile applications(android,iOS)</li>
                              <li>MobileWeb (MWeb)</li>
                              <li>API/WebService Testing</li>
                              <li>DB Testing</li>
                            </ol>
                            Within one Automation Script you can have
                            combination of Test Steps for
                            <ul>
                              <li>Web application</li>
                              <li>Mobile applications(android,iOS)</li>
                              <li>MobileWeb (MWeb)</li>
                              <li>API/WebService Testing</li>
                              <li>DB Testing</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingfive">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapsefive"
                              aria-expanded="false"
                              aria-controls="collapsefive"
                            >
                              What happens when the step fails? How do I control
                              the next steps ?<i className="ti-angle-down"></i>
                              <i className="ti-minus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapsefive"
                          className="collapse"
                          aria-labelledby="headingfive"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <ul>
                              <li>
                                Within one Automation Script you can have
                                combination of Test Steps for{" "}
                              </li>
                              <li>The various options are:</li>
                              <ol>
                                <li>
                                  Mark this step as Failed and continue
                                  execution
                                </li>
                                <li>
                                  Mark this step as Warning and continue
                                  execution
                                </li>
                                <li>
                                  Mark this step as Failed and stop script
                                  execution
                                </li>
                                <li>
                                  Mark this step as Failed and stop current
                                  module execution
                                </li>
                                <li>
                                  Mark this step as Failed and stop complete
                                  execution
                                </li>
                              </ol>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingfive">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapsefive"
                              aria-expanded="false"
                              aria-controls="collapsefive"
                            >
                              How will Mobile app Automation Scripts be
                              executed?<i className="ti-angle-down"></i>
                              <i className="ti-minus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapsefive"
                          className="collapse"
                          aria-labelledby="headingfive"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            You can execute Mobile automation Script on
                            <ol>
                              <li>Real Devices.</li>
                              <li>On Emulators/Simulators.</li>
                              <ul>
                                <li>
                                  We have integrated the platform with common
                                  Cloud Based device farms.
                                </li>
                                <li>
                                  We would be able to integrate it with any
                                  other cloud-based platforms in a matter of
                                  days.
                                </li>
                              </ul>
                            </ol>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingfive">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapsefive"
                              aria-expanded="false"
                              aria-controls="collapsefive"
                            >
                              How secure are the test data files, any data, and
                              Passwords on your platform?
                              <i className="ti-angle-down"></i>
                              <i className="ti-minus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapsefive"
                          className="collapse"
                          aria-labelledby="headingfive"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <ul>
                              <li>
                                You can encrypt & store the files in our test
                                data section.
                              </li>
                              <li>
                                In the case of a cloud licence, the test data
                                files will be saved on our standard cloud
                                storage, and we will maintain their security.
                              </li>
                              <li>
                                In the case of On Premise, the test data files
                                will be stored on your servers.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingfive">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapsefive"
                              aria-expanded="false"
                              aria-controls="collapsefive"
                            >
                              Do you provide traceability of manual test cases
                              with scripts for automation?
                              <i className="ti-angle-down"></i>
                              <i className="ti-minus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapsefive"
                          className="collapse"
                          aria-labelledby="headingfive"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <ul>
                              <li>
                                Yes, the Tree structure view of modules clearly
                                shows the mapping of the manual test cases with
                                its corresponding automation scripts.{" "}
                              </li>
                              <li>
                                If a manual test case exists for which an
                                automation script has not been created, you may
                                quickly track it down.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <button className="show_more_btn" onClick={showMoreData}>
                    {!showMore ? "Show More" : "Show less"}
                  </button>
                </div>
              </div>
            </div>
            <p>
              Looking for something else ? <span><Link to="/contact-us"> Contact us </Link></span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PlatformFaq;

import react from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

 const SkeletonLoading = ({ itemCount }) => {
    const skeletons = Array.from({ length: itemCount }, (_, index) => (
      
        <Skeleton width={220} height={150} />
      
    ));
  
    return <div style={{ display: 'flex' }}>{skeletons}</div>;
  };

  export default SkeletonLoading;
import React from "react";

const NodataFound = () => {
  return (
    <div>
      <div>{/* <NodataFoundImg/> */}</div>
      <div className="NoReleventdata_text">No Relvent Data Found</div>
    </div>
  );
};
export default NodataFound;

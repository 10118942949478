import React, { useState, useEffect } from "react";
import axios from "axios";

const SupportedVideo = (props) => {
  let [suppportingVideo, setSupprtingVIdeo] = useState(props?.data);
  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://script.googleusercontent.com/a/macros/testyantra.com/echo?user_content_key=lFTtrsnVJdMm3C2OeYQZFQFJWgImx6v1dh5CbYFgt_uq4PGGv4FAsfdV-Rjcqu3QuNwdMijG_OpTA0JOHCCzIdqVLs8IEG7AOJmA1Yb3SEsKFZqtv3DaNYcMrmhZHmUMi80zadyHLKAaie3BOJNjLK_YV2U5F_P7aAD8aA7uc1e-pibTtOXMVAb3YZNMQZiW04imCEXTSqBDLUull8BPd6syAvUVh8raAUn-0L7cblmKCb6Hw8wLM6X37TLXigwyQTb0pv62PFsYZpTak_o22Q&lib=M2STiqZDcjpu21eUlkl_UA6rPthto8B-2"
  //     )
  //     .then((response) => {
  //       setNlpAsset(response?.data);
  //     })
  //     .catch((error) => {
  //       console.error("Nlp assets data is not coming", error);
  //     });
  // }, []);
  
  return (
    <section className="pb_30">
      <div className="container">
        <h2 className="benefit_head prodcolor mt-3">Supported Video</h2>
        <div className="asset_head_line ml-70"></div>
        <div className="row">
          {
            suppportingVideo?.map((videodata)=>{
              return (
                <>
                <div className="col-lg-3">
                <div className="supported_video mt-3">
                    <img src={videodata?.link} alt="youtube play button" />
                </div>
                <a className="support_video_link" href={videodata?.link}>{videodata?.videoTitle}</a>
            </div>
                </>
              )
            })
          }
            {/* <div className="col-lg-3">
                <div className="supported_video mt-3">
                    <img src={require("../../img/assets/youtube-play-button.svg").default} alt="youtube play button" />
                </div>
                <a className="support_video_link" href="">Lorem ipsum dolor sit amet,</a>
            </div>
            <div className="col-lg-3">
                <div className="supported_video mt-3">
                    <img src={require("../../img/assets/youtube-play-button.svg").default} alt="youtube play button" />
                </div>
                <a className="support_video_link" href="">Lorem ipsum dolor sit amet,</a>
            </div>
            <div className="col-lg-3">
                <div className="supported_video mt-3">
                    <img src={require("../../img/assets/youtube-play-button.svg").default} alt="youtube play button" />
                </div>
                <a className="support_video_link" href="">Lorem ipsum dolor sit amet,</a>
            </div>
            <div className="col-lg-3">
                <div className="supported_video mt-3">
                    <img src={require("../../img/assets/youtube-play-button.svg").default} alt="youtube play button" />
                </div>
                <a className="support_video_link" href="">Lorem ipsum dolor sit amet,</a>
            </div> */}
        </div>
      </div>
    </section>
  );
};

export default SupportedVideo;

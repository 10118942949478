import React, { useState, useEffect } from "react";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import { bottom } from "@popperjs/core";
import {
  getAssetByCategories,
  getAllNLPByFilter,
  getAssetsBySearch,
} from "../../api/api_services";
import AssetsRating from "./assets_ratings";
import { useHistory } from "react-router-dom";
import NodataFound from "./nodataFound";

const StepGroupListingAsset = () => {
  let [NlpAssest, setNlpAsset] = useState([]);
  let [activeTab, setactiveTab] = useState("");
  const [callingAPI, setCallingAPI] = useState(false);
  const [activebutton, setActivebutton] = useState({
    all: true,
    web: false,
    webandmobile: false,
    android: false,
    ios: false,
  });
  const [pageno, setpageNo] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalpage, setTotalPage] = useState(0);
  const [assetsTypeID, setAssetsTypeID] = useState();
  const[searchData,setSearchData]=useState("")
  const stepGroupdata = localStorage.hasOwnProperty("assets-headers-data")
    ? JSON.parse(localStorage.getItem("assets-headers-data"))?.homeScreen
        ?.assetsSections
    : "";
  const search = window.location.search;
  const key = new URLSearchParams(search).get("key");
  let history = useHistory();
  useEffect(() => {
    getAssetID();
  }, []);

  const getAssetID = () => {
    let typeId;
    let assetstype =
      localStorage.hasOwnProperty("assets-type") &&
      JSON.parse(localStorage.getItem("assets-type"));
    assetstype.map((assets) => {
      if (assets.type === "StepGroups") {
        typeId = assets.id;
      }
    });
    handleActivebutton(key, typeId);
  };
  // const getAllStepGroup = async (typeId, pageno) => {
  //   setAssetsTypeID(typeId);
  //   setCallingAPI(false);
  //   let response = await getAssetByCategories(typeId, pageno, pageSize);
  //   if (
  //     response?.data?.responseCode === 200 &&
  //     response?.data?.message === "SUCCESS"
  //   ) {
  //     if (pageno === 0) {
  //       setNlpAsset(response?.data?.responseObject?.content);
  //       setTotalPage(response?.data?.responseObject?.totalPages);
  //     } else {
  //       let temparray = NlpAssest.concat(
  //         response?.data?.responseObject?.content
  //       );
  //       setNlpAsset(temparray);
  //     }

  //     setpageNo(pageno + 1);
  //     setCallingAPI(true);
  //   } else {
  //     setNlpAsset([]);
  //   }
  // };
  // const getStepGroupBytFilter = async (filter, typeId) => {
  //   setAssetsTypeID(typeId);
  //   setCallingAPI(false);
  //   let response = await getAllNLPByFilter(filter, pageno, pageSize, typeId);
  //   if (
  //     response?.data?.responseCode === 200 &&
  //     response?.data?.message === "SUCCESS"
  //   ) {
  //     if (pageno === 0) {
  //       setNlpAsset(response?.data?.responseObject?.content);
  //       setTotalPage(response?.data?.responseObject?.totalPages);
  //     } else {
  //       let temparray = NlpAssest.concat(
  //         response?.data?.responseObject?.content
  //       );
  //       setNlpAsset(temparray);
  //     }

  //     setpageNo(pageno + 1);
  //     setCallingAPI(true);
  //   } else {
  //     setNlpAsset([]);
  //   }
  // };
  const getAllStepGroup = async (searchvalue,TypeID, page, activetab) => {
    setAssetsTypeID(TypeID);
    setCallingAPI(false);
    let payload =
      activetab === "all"
        ? {
            key: searchvalue,
            typeId: TypeID,
            page: page,
            size: 10,
          }
        : {
            key: searchvalue,
            typeId: TypeID,
            page: page,
            size: 10,
            assetsTags: [activetab],
          };
    let response = await getAssetsBySearch(payload);
    console.log("getSearchedData", response);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      if (page=== 0) {
        setNlpAsset(response?.data?.responseObject?.content);
        setTotalPage(response?.data?.responseObject?.totalPages);
      } else {
        let temparray = NlpAssest.concat(
          response?.data?.responseObject?.content
        );
        setNlpAsset(temparray);
      }

      setpageNo(page + 1);
      setCallingAPI(true);
    } else {
      setNlpAsset([]);
    }
  
    
  };
  const handleActivebutton = (key, typeId) => {
    setAssetsTypeID(typeId);
    switch (key) {
      case "all": {
        setActivebutton({
          all: true,
          web: false,
          webandmobile: false,
          android: false,
          ios: false,
        });
        setactiveTab("all");
        getAllStepGroup(searchData,typeId, 0,"all");
        break;
      }
      case "web": {
        setActivebutton({
          all: false,
          web: true,
          webandmobile: false,
          android: false,
          ios: false,
        });
        setactiveTab("Web");
        getAllStepGroup(searchData,typeId, 0,"Web");;
        break;
      }
      case "webandmobile": {
        setActivebutton({
          all: false,
          web: false,
          webandmobile: true,
          android: false,
          ios: false,
        });
        setactiveTab("Web_And_Mobile");
        getAllStepGroup(searchData,typeId, 0,"Web_And_Mobile");
        break;
      }
      case "android": {
        setActivebutton({
          all: false,
          web: false,
          webandmobile: false,
          android: true,
          ios: false,
        });
        setactiveTab("Android");
        getAllStepGroup(searchData,typeId, 0,"Android");
        break;
      }
      case "ios": {
        setActivebutton({
          all: false,
          web: false,
          webandmobile: false,
          android: false,
          ios: true,
        });
        setactiveTab("iOS");
        getAllStepGroup(searchData,typeId, 0,"iOS");
        break;
      }
      default: {
        setActivebutton({
          all: true,
          web: false,
          webandmobile: false,
          android: false,
          ios: false,
        });
        setactiveTab("all");
        getAllStepGroup(searchData,typeId, 0,"all");;
        break;
      }
    }
  };
  const handlescroll = (event) => {
    if (callingAPI && totalpage > pageno) {
      getAllStepGroup(searchData,assetsTypeID, pageno,activeTab)
      // if (key === "all") {
      //   getAllStepGroup(assetsTypeID, pageno);
      // } else {
      //   getStepGroupBytFilter(activeTab, assetsTypeID, pageno);
      // }
    }
  };
  const handleSearch = (e) => {
    console.log("handle Search", e.target.value.length);
    setSearchData(e.target.value)
    if(e.target.value.length===0){
          setSearchData(e.target.value)
          getAllStepGroup(e.target.value, assetsTypeID, 0, activeTab);
      }
  };
  const handleEnterKeyPress=(event)=>{

    if (event.key === 'Enter') {
      if(searchData.length===0){
        getAllStepGroup("", assetsTypeID, 0, activeTab);
      }else{
        getAllStepGroup(searchData, assetsTypeID, 0, activeTab);
         
        
      }
    }
  };
  const handleSearchBtn=()=>{
    getAllStepGroup(searchData, assetsTypeID, 0, activeTab);
  }

  return (
    <section className="most_used_assets pb_30">
      <div className="container">
        <h2 className="asset_head_two pt-50">
          {stepGroupdata?.stepGrupHeader}
        </h2>
        

        {/* <div className="fr">
            <TextField
              //label="With normal TextField"
              placeholder="Search"
              variant="standard"
              sx = {{
                width: "260px",
                height: "26px",
                border: "1px solid #DB7221",
                background: "#DB722126",
                borderRadius: "26px",
                "& .MuiInput-underline:before": { display : "none"},
                "& .MuiInput-underline:after": { display : "none"},
                padding: "0px 0 5px",
                paddingLeft: "5px",
                fontSize: "8px",
                
                
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment className="assest_type_search_txt">
                    <IconButton>
                      <SearchIcon className="assest_type_search_icon"/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </div> */}

        <div className="d-flex ml-50">
          <div className="asset_head_line"></div>
          <div className="asset_list_line_grey w90"></div>
        </div>
        <p className="asset_head_des mt-2">
              {stepGroupdata?.stepGroupDescription}
            </p>
        <div className="row mt_40">
          <div className="col-lg-1"></div>
          <div className="d-flex w85">
            <div className="col-lg-10 text-left">
              <div className="nlp_search_box">
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Search for StepGroup"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#565872",
                    width: "100%",
                    fontFamily: "Poppins, SemiBold",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  InputProps={{
                    style: {
                      color: "#565872",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "Poppins, Regular",
                    },
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  onKeyDown={handleEnterKeyPress}
                />
              </div>
            </div>
            <div className="col-lg-1">
              <div className="asset_search_btn">
                <button onClick={()=>{handleSearchBtn()}}>
                  <i className="ti-search nlp_search_btn"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <div className="item_type pt-50">
              <div
                className={
                  activebutton.all
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("all", assetsTypeID);
                }}
              >
                All items
              </div>
              <div
                className={
                  activebutton.web
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("web", assetsTypeID);
                }}
              >
                Web
              </div>
              <div
                className={
                  activebutton.webandmobile
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("webandmobile", assetsTypeID);
                }}
              >
                Web & Mobile
              </div>
              <div
                className={
                  activebutton.android
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("android", assetsTypeID);
                }}
              >
                Android
              </div>
              <div
                className={
                  activebutton.ios
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("ios", assetsTypeID);
                }}
              >
                iOS
              </div>
            </div>
          </div>
        </div>
        {
          NlpAssest?.length>0?( <div
            className="row pr_40 pl_40 scroll_container"
            onScroll={handlescroll}
          >
            {NlpAssest?.map((data) => (
              <div className="col-lg-3 pl-30 pr_30 mt_50">
                <div
                  className="used_asset_box"
                  // onClick={() => {
                  //   history.push(`/step-group-description?id=${data.id}`);
                  // }}
                  onClick={()=>{history.push(`/stepgroup-list?id=${data.id}`)}}
                >
                  <div className="sce_btn">
                    <div className="asset_type">
                      <img
                        src={require("../../img/assets/excel.svg").default}
                        alt="excel"
                      />
                    </div>
                  </div>
                  <h3 className="mt-2 asset_type_head">{data.title}</h3>
                  <h4 className="asset_des ml-3 mr-3 mt_20">
                    {data.description}
                  </h4>
                  <div className="ml-3 mt-1 star_rating">
                    <AssetsRating rating={data?.rating} />
                  </div>
                </div>
              </div>
            ))}
          </div>):(callingAPI && (<div className="nodatafoun-container mt_15">
        <NodataFound/>
          </div>))
        }
       
      </div>
    </section>
  );
};

export default StepGroupListingAsset;

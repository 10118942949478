import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
import Slide from 'react-reveal/Slide';




class TemplateStore extends Component {
    render(){
        
        return(
            <section className="template_store">
                <div className="container">    
                 
                    <div className="row mb_40">
                    
                        <div className="col-lg-12"> 
                        <Slide left>
                            <h2>Templates Store</h2>
                            <p>The FireFlink platform allows for extensive personalization of reports, analytics, and dashboards. Its unique architecture makes it simple to incorporate new templates via the APIs that are kept open for integration.</p>
                            <ul>
                                    <li> <img src={require("../../img/assets/tick.svg").default} alt="" /> Plug-and-Play test platform to meet specific requirements</li>
                                    <li> <img src={require("../../img/assets/tick.svg").default} alt="" /> Choose preloaded templates for reports, dashboards, and analytics to meet your company's needs</li>
                                    <li> <img src={require("../../img/assets/tick.svg").default} alt=""/> Contribute to our store by sharing your templates, which will benefit the FireFlink community significantly</li>
                                    <li> <img src={require("../../img/assets/tick.svg").default} alt=""/> Experience personalized software testing and seamless integrations all at once</li>
                                    
                                </ul>
                                <div className="sce_btn">
                            <div className="line"></div>
                            </div>
                        </Slide>   
                        </div>
                     
                        
                    
                    </div>
                         
                    
                </div>    
            </section>
        )
    }
}

export default TemplateStore;
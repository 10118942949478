import React, { useRef, useEffect } from "react";

function SelfLearningVideoContent({ title, videoId }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && videoId) {
      videoRef.current.load();
    }
  }, [videoId]);

  return (
    <div className="container self_learning">
      <h3 className="f_size_20 f_600 prodcolor f_p mt_20">{title}</h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="self_learning_video">
            <video
              width="100%"
              height="380"
              controls
              controlsList="nodownload"
              ref={videoRef}
            >
              <source src={videoId} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelfLearningVideoContent;

import React, { useEffect, useState, useRef } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HowToUse = (props) => {
  const [isEndOfSection, setIsEndOfSection] = useState(false);
  const [isStartOfSection, setIsStartOfSection] = useState(true);
  const [steps, setSteps] = useState(props?.steps);
  const sliderRef = useRef(null);
  const settings = {
    // infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    beforeChange: (current, next) => {
      setIsStartOfSection(next === 0);
    },
  };
  const slidePrev = () => {
    sliderRef.current.slickPrev();
    setIsEndOfSection(false);
  };
  const slideNext = () => {
    sliderRef.current.slickNext();
    setIsStartOfSection(false);
  };

  const handleAfterChange = (currentSlide) => {
    setIsEndOfSection(currentSlide + 3 >= steps.length);
  };

  return (
    <SkeletonTheme baseColor="#eee" highlightColor="#FFFFFF">
      <section className="most_used_assets pb_30">
        <div className="container">
          <div>
            <h2 className="benefit_head prodcolor ml-20">How to Use</h2>
            <div className="asset_head_line ml-70"></div>
            <p className="asset_head_des"></p>
          </div>

          <div className="row">
            {steps?.map((data, index) => {
              return (
                <>
                  <div className="col-lg-3 mt_20 d-flex mb-3">
                    <div className="how_to_use_box ml-20 mr_20">
                      <div className="sce_btn">
                        <div className="how_to_use_step mt-20">
                          <h3>{data?.stepNumber}</h3>
                        </div>
                      </div>
                      <div className="how_to_use_head_back">
                        <h4 className="how_to_use_head mt-3 ml-3 mr-3 pt-1 pb-1 text-center">
                          {data?.stepName}
                        </h4>
                      </div>
                      <p className="how_to_use_para ml_15 mt_10 mb-2 mr_15">
                        {data?.description}
                      </p>
                    </div>
                    {/* {
                index<(steps?.length-1) && ( <img
                  className="arrow_img"
                  src={require("../../img/assets/how-to-use-arrow.svg").default}
                  alt="arrow"
                />)
              } */}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </SkeletonTheme>
  );
};

export default HowToUse;

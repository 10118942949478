import React, { Component } from "react";
import Reveal from "react-reveal";
class Breadcrumb extends Component {
  render() {
    var {
      Ptitle,
      Pdescription,
      BannercssClass,
      BannerimgClass,
      breadcrumbClass,
      imgName,
    } = this.props;
    return (
      <section className={`${BannercssClass} ${BannerimgClass}`}>
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 white f_size_50 l_height50 mb_20">
                {Ptitle}
              </h1>
            </Reveal>
            <p className="f_400 white f_size_17 l_height26">{Pdescription}</p>
            <div className="sce_btn">
              <div className="head_line"></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Breadcrumb;

import React, { useEffect, useState } from "react";
import AssetsNavbar from "../components/AssetsNavbar";
import FireAssetsBanner from "../components/Banner/FireAssetsBanner";
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from "../components/Footer/FooterData";
import AssetsProduct from "../components/assets/AssetsProduct";
import HowCloudWorks from "../components/firecloud/HowCloudWorks";
import TemplateStore from "../components/assets/TemplateStore";
import PlatformFaq from "../components/fireflink-platform/PlatformFaq";
import GetStarted from "../components/GetStarted";
import Explore from "../components/Explore";

import { Helmet } from "react-helmet";
import BuyAssets from "../components/assets/BuyAssets";
import MostUsedAssets from "../components/assets/MostUsedAssets";
import NlpAssest from "../components/assets/NlpAssest";
import ElementsAssets from "../components/assets/ElementsAssets";
import StepGroupAssets from "../components/assets/StepGroupAssets";
import HappyClients from "../components/Home/HappyClients";
import AssetResult from "../components/assets/AssetResult";
import AssetResultCategory from "../components/assets/AssetResultCategory";
import {
  getAllAssetsType,
  getAssetsData,
  getAssetsBySearch,
  getNLPlistBySearch,
} from "../api/api_services";
import UniqueFeaturesAssets from "../components/assets/UniqueFeaturesAssets";
import AssetsFeaturesData from "../components/assets/AssetsFeaturesData";

const FireflinkAssetNew = () => {
  const [headersdata, setHeadersdata] = useState();
  const [dataloading, setDataLoading] = useState(true);
  const [searchedData, setSearchedData] = useState("");
  const [assetsList, setAssetsList] = useState([]);
  const [pageno, setPageno] = useState(0);
  const [totalpages, setTotalPages] = useState(0);
  const [nlplist, setnlplist] = useState([]);
  const [callingApi,setcallingApi]=useState();
  const[assetId,setAssetId]=useState()
  useEffect(() => {
    getAllAssetsHeaders();
  }, []);
  const getAllAssetsHeaders = () => {
    getAssetsData()
      .then((response) => {
        console.log("getAllAssetsHeader~s", response);
        if (
          response?.data?.message === "SUCCESS" &&
          response?.data?.responseCode === 200
        ) {
          localStorage.setItem(
            "assets-headers-data",
            JSON.stringify(response?.data?.responseObject[0]?.details)
          );
          setHeadersdata(response?.data?.responseObject[0]?.details);
          getAssetsType();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAssetsType = () => {
    getAllAssetsType()
      .then((response) => {
        if (
          response?.data?.message === "SUCCESS" &&
          response?.data?.responseCode === 200
        ) {
          localStorage.setItem(
            "assets-type",
            JSON.stringify(response?.data?.responseObject)
          );
        }
        setDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAssetsList = async (searchvalue, assetTypeID, page) => {
    setcallingApi(false)
   
    let payload =
      assetTypeID === ""
        ? {
            key: searchvalue,
            page: page,
            size: 10,
          }
        : {
            key: searchvalue,
            typeId: assetTypeID,
            page: page,
            size: 10,
          };
    let response = await getAssetsBySearch(payload);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      if (page === 0) {
        setAssetsList(response?.data?.responseObject?.content);
        setTotalPages(response?.data?.responseObject?.totalPages);
        setDataLoading(false);
        setPageno(page + 1);
        setcallingApi(true);
        getAllnlp(searchvalue,"all")
      } else {
        let temparray = assetsList.concat(
          response?.data?.responseObject?.content
        );
        setAssetsList(temparray);
         setPageno(page + 1);
        setDataLoading(false);
        setcallingApi(true);
      }

     
    } else {
      setAssetsList([]);
    }
  };
  const getAllnlp = async (searchvalue) => {
    let payload = {
      key: searchvalue
    };
    let response = await getNLPlistBySearch(payload);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      setnlplist(response?.data?.responseObject);
    }
  };
  const handleSearch = (category, searchvalue, assetTypeID) => {
    console.log("handleSearch", searchvalue, searchvalue.length);
    setDataLoading(true);
    setAssetId(assetTypeID)
    if (searchvalue?.length > 0) {
      setSearchedData(searchvalue);
      getAssetsList(searchvalue, assetTypeID, 0);
      // getAllnlp()
    } else {
      setDataLoading(false);
      setSearchedData("");
    }
  };
  const handlescroll=()=>{
    if(callingApi && totalpages>pageno){
      getAssetsList(searchedData, assetId, pageno)
    }
  }
  useEffect(()=>{
    if(searchedData.length>0){
      let ele=document.getElementById("fireflink-Assets");
       ele.scrollIntoView({behavior: 'smooth', block: 'start' });
    
     
    }

  },[searchedData])
  
  return (
    <div className="body_wrapper" >
      <AssetsNavbar
        slogo="sticky_logo"
        assetMenu="menu_blue"
        mClass="menu_four"
        nClass="w_menu"
        blueArrow="blue_arrow"
        blueHomeIcon="blue_home_icon"
        hbtnClass="btn_get_radious menu_custfive"
      />
      <FireAssetsBanner data={headersdata} handleSearch={handleSearch} />
      {!dataloading && (
        <>
          {searchedData.length>0  ? (
            <>
              <AssetResult
                assetslist={assetsList}
                searchedData={searchedData}
                handlescroll={handlescroll}
              />
              <AssetResultCategory nlplist={nlplist} searchkey={searchedData}/>
            </>
          ) : (
            <>
              <MostUsedAssets data={headersdata?.homeScreen?.assetsSections} />
              <NlpAssest data={headersdata?.homeScreen?.assetsSections} />
              <ElementsAssets data={headersdata?.homeScreen?.assetsSections} />
              <StepGroupAssets data={headersdata?.homeScreen?.assetsSections} />
         
            </>
          )}
  

        <UniqueFeaturesAssets UniqueFeaturesAssets={AssetsFeaturesData} />
          <GetStarted />
          <Explore />
          <FooterSecurity FooterData={FooterData}/>

          <Helmet>
            <title>
              A Unique Marketplace Offering Complete Software Testing Assets
            </title>
            <meta
              name="description"
              content="Platform Assets, Domain Assets, and Templates on FireFlink Assets, an exclusive software testing marketplace. Get your test automation project up and running in no time."
            />
            <meta name="keywords" content="Software Testing Assets" />
          </Helmet>
        </>
      )}

     <div id="fireflink-Assets"></div>
    </div>
  );
};

export default FireflinkAssetNew;

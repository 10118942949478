import React,{useEffect,useState} from "react";
import AssetsNavbar from '../components/AssetsNavbar';
import {Helmet} from "react-helmet";
import ExcelDetail from "../components/assets/ExcelDetail";
import AssetDescription from "../components/assets/AssetDescription";
import AssetInnerBanner from "../components/assets/AssetInnerBanner";
import WhenToUse from "../components/assets/WhenToUse";
import { homeadditionalQuestion, homefaqData } from "../components/HomepageFaq";
import CommonFaq from "../components/Common/Faq";
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from "../components/Footer/FooterData";
import {getNLPCategoriesDetailsByTitle,getNLPCategoriesbyID} from "../api/api_services"
import ElementsAssets from "../components/assets/ElementsAssets";
import ElementsDescription from "../components/assets/ElementsDescription";
import ProjectElements from "../components/assets/ProjectElements";
import GetStarted from "../components/GetStarted";
import Explore from "../components/Explore";


const ListOfStepGroup = () => {
  const search = window.location.search;
  const  [details, setDetails] = useState({});
  const [benefits,setBenefit]=useState([]);
  const [dataLoading,setdataLoading]=useState(true);
  const [ScenariosList,setScenariosList]=useState([]);
  const[QuestionAndAnswer,setQuestionAndAnswer]=useState([]);
  const [typeID,setTypeID]=useState("")
  useEffect(()=>{
    
    getNLPCategoriesDetails()
  },[])
  const getNLPCategoriesDetails = () => {
    const id = new URLSearchParams(search).get("id");
  getNLPCategoriesbyID(id).then((response)=>{
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      setDetails(response?.data?.responseObject);
      setTypeID(response?.data?.responseObject?.typeId)
      getStepGroupDetails(response?.data?.responseObject?.title,response?.data?.responseObject?.typeId)
    } else {
      setDetails({});
    
    }
  }).catch((error)=>{
    console.log(error)
  })
   
  };
  const getStepGroupDetails=(title,typeId)=>{
    getNLPCategoriesDetailsByTitle(title,typeId).then((response)=>{
     console.log("getNLPCategoriesDetailsByTitle",response)
     if( response?.data?.responseCode === 200 && response?.data?.message === "SUCCESS"){
      setBenefit(response?.data?.responseObject[0]?.benefits);
      setScenariosList(response?.data?.responseObject[0]?.details?.scenario);
      setQuestionAndAnswer(response?.data?.responseObject[0]?.details?.questionAndAnswer)
      setdataLoading(false)
     }else{
      setBenefit([])
      setdataLoading(false)
     }

    }).catch((error)=>{
      console.log("error")
    })
  }
  return (
    <div className="body_wrapper">
      {
         !dataLoading && (
          <>
          <AssetsNavbar slogo="sticky_logo" firstMenu="first_menu" mClass="menu_four" nClass="w_menu" blueArrow="blue_arrow" blueHomeIcon="blue_home_icon" hbtnClass="btn_get_radious menu_custfive"/>
      <AssetInnerBanner />
      <ElementsDescription details={details} benefits={benefits}/>
      {/* <ProjectElements title={details?.title}/> */}
      <ProjectElements  title={details?.title} category={"StepGroup"} typeId={typeID}/>
      <WhenToUse ScenariosList={ScenariosList}/>
      <CommonFaq data={QuestionAndAnswer}/>
      <GetStarted/>
      <Explore />
     
      <FooterSecurity FooterData={FooterData} />

      <Helmet>
                <title>A Unique Marketplace Offering Complete Software Testing Assets</title>
                <meta name="description" content="Platform Assets, Domain Assets, and Templates on FireFlink Assets, an exclusive software testing marketplace. Get your test automation project up and running in no time." />
                <meta name="keywords" content="Software Testing Assets"/>
      </Helmet>
          </>
         )
      }
      
    </div>
  );
  
 
};

      


export default ListOfStepGroup;

import React, { Component } from "react";
import Slide from "react-reveal/Slide";

class BuyAssets extends Component {
  render() {
    return (
      <section className="buyfireflink mt-5">
        <div className="container">
          <div className="row mb_40">
            {/* <div className="col-lg-6"> 
                        <Slide left>
                            <h3>Want to buy FireFlink <br/> Cloud ?</h3>
                            <button className="buyfireflink_click">Click here</button>
                                </Slide>   
                        </div> */}

            <div className="col-lg-12">
              <Slide right>
                <h3>Want to know more about FireFlink Assets ? </h3>
                <div className="sce_btn">
                  <a href="/contactus" className="buyfireflink_click">
                    Contact Us
                  </a>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BuyAssets;

import React, { useState } from "react";
import PreRequitesLayout from "../components/PreRequitesLayout";

const SafariBrowserLimitation = () => {
  return (
    <PreRequitesLayout>
      <section className="safari_browser_limitation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
            <h3 className="mt_20 prodcolor f_size_22 f_600 f_p">
              Safari Browser automation limitation
            </h3>
            <div className="mt_20 safari_browser_box">
              
              <h3 className="f_size_17 f_600 f_p prodcolor mt_15 pl_20">Prerequisites for automation in Safari browser:</h3>
              <p className="f_size_15 f_600 f_r f_400 fourth_color pl_20">"Enable Remote Automation Feature" must be enabled in Safari browser</p>
              <div className="safari_border_bottom"></div>
              <h3 className="f_size_17 f_600 f_p prodcolor mt_15 pl_20">How To Enable</h3>
              <p className="f_size_15 f_600 f_r fourth_color pl_20">Latest Mac versions</p>
              <ul>
                <li className="f_size_15 f_r f_normal fourth_color">Open Safari browser</li>
                <li className="f_size_15 f_r f_normal fourth_color">Navigate to Safari Settings {"->"} Advanced {"->"} select "Show web developer” features checkbox</li>
                <li className="f_size_15 f_r f_normal fourth_color">In the Safari browser, click the "Develop" menu, click "Developer options" and select the "Enable Remote Automation" feature.</li>
              </ul>
              <div className="safari_border_bottom"></div>
              <h3 className="f_size_17 f_600 f_p prodcolor mt_15 pl_20">For older Mac version</h3>
              <ul>
                <li className="f_size_15 f_r f_normal fourth_color">Open Safari browser</li>
                <li className="f_size_15 f_r f_normal fourth_color">Open Settings {"->"} Advanced {"->"} select the "Show Develop menu in menu bar”</li>
                <li className="f_size_15 f_r f_normal fourth_color">In the Safari browser, click the "Develop" menu and select “Allow Remote Automation” menu</li>
              </ul>
              <div className="safari_border_bottom"></div>
              <h3 className="f_size_17 f_600 f_p prodcolor mt_15 pl_20">Safari browser automation limitations</h3>
              <p className="f_size_15 f_r f_normal fourth_color pl_20">"Safari browser cannot support more than one instance at a time on one machine due to this limitation of Safari browser, we cannot support the following scenarios in Fireflink.</p>
              <p className="f_size_15 f_r f_normal fourth_color pl_20">1. If a user tries to launch the Safari browser without closing the previous session, the script fails, so for all the scripts the user should mention closing the browser.</p>
              <p className="f_size_15 f_r f_normal fourth_color pl_20">2. The combination of the below suite fails</p>
              <div className="pl_20">
              <ul>
                <li className="f_size_15 f_r f_normal fourth_color">Suite with a sequential run, single machine and more than one form with the Safari browser</li>
                <li className="f_size_15 f_r f_normal fourth_color">Suite with a parallel run, single machine and more than one form or parallel run with the Safari browser</li>
                <li className="f_size_15 f_r f_normal fourth_color">Suite with a parallel run, multiple machines and if each machine has more than one form or more than one parallel run.</li>
                <li className="f_size_15 f_r f_normal fourth_color">Suite with a distributed run, single machine and more than one form with the Safari browser</li>
                <li className="f_size_15 f_r f_normal fourth_color">Suite with a distributed run, single machine and more than one parallel run with Safari browser</li>
                <li className="f_size_15 f_r f_normal fourth_color">Suite with a distributed run, multiple machines and if each machine has more than one form or more than one parallel run.</li>
              </ul>
              </div>
              <p className="f_size_15 f_r f_normal fourth_color pl_20">3. Headless automation is not possible in the Safari browser.</p>
              <div className="safari_border_bottom"></div>
              <h3 className="f_size_17 f_600 f_p prodcolor mt_15 pl_20">Below is the list of NLPs which are not supported by the Safari browser</h3>
              
              <ul>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open browser by disabling all notifications</li>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open browser by enabling all notifications</li>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open browser by disabling camera</li>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open browser by enabling camera</li>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open browser by disabling location</li>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open browser by enabling location</li>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open browser by disabling microphone</li>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open browser by enabling microphone</li>
                <li className="f_size_15 f_r f_normal fourth_color">Browser window : Open or in headless mode</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </PreRequitesLayout>
  );
};

export default SafariBrowserLimitation;

import React, { useEffect, useState } from "react";
import AssetsNavbar from '../components/AssetsNavbar';
import {Helmet} from "react-helmet";
import ExcelDetail from "../components/assets/ExcelDetail";
import AssetDescription from "../components/assets/AssetDescription";
import SupportedVideo from "../components/assets/SupportedVideo";
import AssetInnerBanner from "../components/assets/AssetInnerBanner";
import HowToUse from "../components/assets/HowToUse";
import RelatedNlp from "../components/assets/RelatedNlp";
import WhenToUse from "../components/assets/WhenToUse";
import { homeadditionalQuestion, homefaqData } from "../components/HomepageFaq";
import CommonFaq from "../components/Common/Faq";
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from "../components/Footer/FooterData";
import {getNLPDescriptionById,getNLPCategoriesDetailsByTitle,getNLPList,getDetailsOfElementsOrStepGoup,getListofElementsOrStepGoup} from "../api/api_services";
import { useHistory } from "react-router-dom";
import GetStarted from "../components/GetStarted";
import Explore from "../components/Explore";


const ElementsDescriptionPage = () => {
  const [nlpdetails,setnlpDetails]=useState();
  const [dataloading,setdataLoading]=useState(true);
  const [nlpList,setNlpList]=useState([]);
  const[rerender,setRerender]=useState();
  const [nlpdatalist,setNLPdataList]=useState([])
  let history = useHistory();
  const [assetsname,setAssetsName]=useState("");
  const [Assetsrating,setAssetsRating]=useState("")
  useEffect(()=>{
    if(dataloading){
      getAllDetailsOfAssets()
    }
   
  },[window.location.search,dataloading])
  // useEffect(()=>{
   
  //     getAllDetailsOfAssets()
 
   
  // },[])
  // useEffect(()=>{
  //   if(dataloading){
  //     getNLPDescription()
  //   }
    

  // },[window.location.search,dataloading])

  // const getNLPDescription=()=>{
  //   const search = window.location.search;
  //   const id = new URLSearchParams(search).get("id");
  //   getNLPDescriptionById(id).then((response)=>{
  //     console.log("getNLPDescription",response)
  //     if(response?.data?.message==="SUCCESS" && response?.data?.responseCode===200){
  //       setnlpDetails(response?.data?.responseObject);
  //       getNLPbenefits(response?.data?.responseObject?.title,id,response?.data?.responseObject?.typeId)
        
  //     }

  //   }).catch((error)=>{
  //     console.log(error)
  //     setdataLoading(false)
  //   })
  // }
  // const getNLPbenefits=(title,id,typeId)=>{
  //   getNLPCategoriesDetailsByTitle(title,typeId).then((response)=>{
  //     if (
  //       response?.data?.responseCode === 200 &&
  //       response?.data?.message === "SUCCESS"
  //     ) {
  //       let temparray=[];
  //       response?.data?.responseObject[0]?.nlpList?.map((item)=>{
  //         if(item?.nlpId!==id && temparray?.length <10){
  //           temparray.push(item)
  //         }
  //       })
  //       setNlpList(temparray);
  //       getRelatedNLPlist(title,typeId)
        
  //     } else {
  //       setNlpList({});
  //       setdataLoading(false)
  //     }

  //   }).catch((error)=>{
  //     console.log(error)
  //   })
  // }

  // const getRelatedNLPlist=async (title,typeId)=>{
  //   let response= await getNLPList(title,typeId);
  //   if (
  //     response?.data?.responseCode === 200 &&
  //     response?.data?.message === "SUCCESS"
  //   ) {
  //     setNLPdataList(response?.data?.responseObject)
  //     setdataLoading(false)
      
  //   } else {
  //     setNLPdataList([]);
  //     setdataLoading(false)
  //   }
  // }

  const hadleRerender=(id)=>{
  
      history.push(`/elements-description?id=${id}&type=elements`)
      setdataLoading(true)
  
    
  }
  
  const getAllDetailsOfAssets=()=>{
    const search = window.location.search;
    const id = new URLSearchParams(search).get("id");
    const Assetstype=new URLSearchParams(search).get("type")
    getDetailsOfElementsOrStepGoup(Assetstype,id).then((response)=>{
      console.log("getAllDetailsOfAssets",response)
      if(response?.data?.responseCode===200 && response?.data?.message==="SUCCESS"){
        setnlpDetails(response?.data?.responseObject)
        setAssetsRating(response?.data?.responseObject?.elementRating);
        setAssetsName(response?.data?.responseObject?.name)
        getRelatedAssets(response?.data?.responseObject?.title,response?.data?.responseObject?.typeId)
        
      }

    }).catch((error)=>{
      console.log("error")
    })
  }

  const getRelatedAssets=(title,typeId)=>{
    getListofElementsOrStepGoup(title,typeId).then((res)=>{
      console.log("getRelatedAssets",res)
      if(res?.data?.responseCode===200 && res?.data?.message==="SUCCESS"){
        setNLPdataList(res?.data?.responseObject)
      }
      setdataLoading(false);
    }).catch((error)=>{
      console.log("Error")
    })
  }
  
  return (
    <div className="body_wrapper">
      {
       !dataloading && (
        <>
        <AssetsNavbar slogo="sticky_logo" firstMenu="first_menu" mClass="menu_four" nClass="w_menu" blueArrow="blue_arrow" blueHomeIcon="blue_home_icon" hbtnClass="btn_get_radious menu_custfive"/>
      <AssetInnerBanner />
      <AssetDescription data={nlpdetails} setRerender={setRerender} name={assetsname} rating={Assetsrating} label={"elements"}/>
      <HowToUse steps={nlpdetails?.details?.howToUse}/>
      <SupportedVideo data={nlpdetails?.supportingVideo}/>
      <RelatedNlp data={nlpdatalist}  hadleRerender={hadleRerender} label={"elements"}/>
      <WhenToUse ScenariosList={nlpdetails?.details?.scenario} />
      <CommonFaq data={nlpdetails?.details?.questionAndAnswer}/>
      <GetStarted/>
      <Explore />
      

      <FooterSecurity FooterData={FooterData} />

      <Helmet>
                <title>A Unique Marketplace Offering Complete Software Testing Assets</title>
                <meta name="description" content="Platform Assets, Domain Assets, and Templates on FireFlink Assets, an exclusive software testing marketplace. Get your test automation project up and running in no time." />
                <meta name="keywords" content="Software Testing Assets"/>
      </Helmet>
        </>
       )
      }
      
    </div>
  );
  
 
};

      


export default ElementsDescriptionPage;


import React, { useEffect, useState } from "react";
import AssetsNavbar from '../components/AssetsNavbar';
import {Helmet} from "react-helmet";
import ExcelDetail from "../components/assets/ExcelDetail";
import AssetDescription from "../components/assets/AssetDescription";
import SupportedVideo from "../components/assets/SupportedVideo";
import AssetInnerBanner from "../components/assets/AssetInnerBanner";
import HowToUse from "../components/assets/HowToUse";
import RelatedNlp from "../components/assets/RelatedNlp";
import WhenToUse from "../components/assets/WhenToUse";
import { homeadditionalQuestion, homefaqData } from "../components/HomepageFaq";
import CommonFaq from "../components/Common/Faq";
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from "../components/Footer/FooterData";
import {getNLPDescriptionById,getNLPCategoriesDetailsByTitle,getNLPList} from "../api/api_services";
import { useHistory } from "react-router-dom";
import GetStarted from "../components/GetStarted";
import Explore from "../components/Explore";


const AssetDescriptionPage = () => {
  const [nlpdetails,setnlpDetails]=useState();
  const [dataloading,setdataLoading]=useState(true);
  const [nlpList,setNlpList]=useState([]);
  const[rerender,setRerender]=useState();
  const [nlpdatalist,setNLPdataList]=useState([])
  let history = useHistory();
  useEffect(()=>{
    if(dataloading){
      getNLPDescription()
    }
    

  },[window.location.search,dataloading])

  const getNLPDescription=()=>{
    const search = window.location.search;
    const id = new URLSearchParams(search).get("id");
    getNLPDescriptionById(id).then((response)=>{
      console.log("getNLPDescription",response)
      if(response?.data?.message==="SUCCESS" && response?.data?.responseCode===200){
        setnlpDetails(response?.data?.responseObject);
        getNLPbenefits(response?.data?.responseObject?.title,id,response?.data?.responseObject?.typeId)
        
      }

    }).catch((error)=>{
      console.log(error)
      setdataLoading(false)
    })
  }
  const getNLPbenefits=(title,id,typeId)=>{
    getNLPCategoriesDetailsByTitle(title,typeId).then((response)=>{
      if (
        response?.data?.responseCode === 200 &&
        response?.data?.message === "SUCCESS"
      ) {
        let temparray=[];
        response?.data?.responseObject[0]?.nlpList?.map((item)=>{
          if(item?.nlpId!==id && temparray?.length <10){
            temparray.push(item)
          }
        })
        setNlpList(temparray);
        getRelatedNLPlist(title,typeId)
        
      } else {
        setNlpList({});
        setdataLoading(false)
      }

    }).catch((error)=>{
      console.log(error)
    })
  }

  const getRelatedNLPlist=async (title,typeId)=>{
    let response= await getNLPList(title,typeId);
    if (
      response?.data?.responseCode === 200 &&
      response?.data?.message === "SUCCESS"
    ) {
      setNLPdataList(response?.data?.responseObject)
      setdataLoading(false)
      
    } else {
      setNLPdataList([]);
      setdataLoading(false)
    }
  }

  const hadleRerender=(id)=>{
    history.push(`/nlp-description?id=${id}`)
    setdataLoading(true)
  }
  
  
  return (
    <div className="body_wrapper">
      {
       !dataloading && (
        <>
        <AssetsNavbar slogo="sticky_logo" firstMenu="first_menu" mClass="menu_four" nClass="w_menu" blueArrow="blue_arrow" blueHomeIcon="blue_home_icon" hbtnClass="btn_get_radious menu_custfive"/>
      <AssetInnerBanner />
      <AssetDescription data={nlpdetails} setRerender={setRerender}/>
      <HowToUse steps={nlpdetails?.details?.howToUse}/>
      <SupportedVideo data={nlpdetails?.supportingVideo}/>
      <RelatedNlp data={nlpdatalist}  hadleRerender={hadleRerender}/>
      <WhenToUse ScenariosList={nlpdetails?.details?.scenario} />
      <CommonFaq data={nlpdetails?.details?.questionAndAnswer}/>
      <GetStarted/>
      <Explore />
      

      <FooterSecurity FooterData={FooterData} />

      <Helmet>
                <title>A Unique Marketplace Offering Complete Software Testing Assets</title>
                <meta name="description" content="Platform Assets, Domain Assets, and Templates on FireFlink Assets, an exclusive software testing marketplace. Get your test automation project up and running in no time." />
                <meta name="keywords" content="Software Testing Assets"/>
      </Helmet>
        </>
       )
      }
      
    </div>
  );
  
 
};

      


export default AssetDescriptionPage;

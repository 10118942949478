const importKey = async () => {
    const encodedKey = new TextEncoder().encode(process.env.REACT_APP_ENCRYPT_DECRYPT_AES_KEY);
    return await crypto.subtle.importKey('raw', encodedKey, { name: 'AES-GCM' }, false, ['encrypt', 'decrypt']);
  };
  const encryptData = async (data) => {
    try {
      const encryptionKey = await importKey();
      const encryptedData = {};
      if (typeof data === 'string') {
        const encodedText = new TextEncoder().encode(data);
        const iv = crypto.getRandomValues(new Uint8Array(12));
        const ciphertextBuffer = await crypto.subtle.encrypt({ name: 'AES-GCM', iv: iv }, encryptionKey, encodedText);
        const ciphertextArray = Array.from(new Uint8Array(ciphertextBuffer));
        const ciphertextBase64 = btoa(String.fromCharCode(...ciphertextArray));
        const encryptedData = `${btoa(String.fromCharCode(...iv))}:${ciphertextBase64}`;
        return encryptedData;
      } else {
        for (const [key, value] of Object.entries(data)) {
          // Encrypt the key
          const encodedKey = new TextEncoder().encode(key);
          const ivKey = crypto.getRandomValues(new Uint8Array(12));
          const encryptedKeyBuffer = await crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: ivKey },
            encryptionKey,
            encodedKey
          );
          const encryptedKeyArray = Array.from(new Uint8Array(encryptedKeyBuffer));
          const encryptedKeyBase64 = btoa(String.fromCharCode(...encryptedKeyArray));
          // Encrypt the value
          const encodedValue = new TextEncoder().encode(value);
          const ivValue = crypto.getRandomValues(new Uint8Array(12));
          const encryptedValueBuffer = await crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: ivValue },
            encryptionKey,
            encodedValue
          );
          const encryptedValueArray = Array.from(new Uint8Array(encryptedValueBuffer));
          const encryptedValueBase64 = btoa(String.fromCharCode(...encryptedValueArray));
          // Combine the IV and ciphertext for both key and value
          const finalEncryptedKey = `${btoa(String.fromCharCode(...ivKey))}:${encryptedKeyBase64}`;
          const finalEncryptedValue = `${btoa(String.fromCharCode(...ivValue))}:${encryptedValueBase64}`;
          // Assign the encrypted key-value pair to the encryptedData object
          encryptedData[finalEncryptedKey] = finalEncryptedValue;
        }
        return JSON.stringify(encryptedData);
      }
    } catch (error) {
      console.error('Encryption error:', error);
      return null;
    }
  };
  const decryptData = async (SignInResponse) => {
    try {
      const encryptionKey = await importKey();
      const decryptedData = {};
      for (const [encryptedKey, value] of Object.entries(SignInResponse)) {
        // Decrypt the key
        const [ivKeyBase64, encryptedKeyBase64] = encryptedKey.split(':');
        const ivKey = new Uint8Array(Array.from(atob(ivKeyBase64), (c) => c.charCodeAt(0)));
        const encryptedKeyArray = new Uint8Array(Array.from(atob(encryptedKeyBase64), (c) => c.charCodeAt(0)));
        const decryptedKeyBuffer = await crypto.subtle.decrypt(
          { name: 'AES-GCM', iv: ivKey },
          encryptionKey,
          encryptedKeyArray
        );
        const decryptedKey = new TextDecoder().decode(decryptedKeyBuffer);
        // Decrypt the value
        if (decryptedKey !== 'userStatus') {
          const [ivBase64, ciphertextBase64] = value.split(':');
          const iv = new Uint8Array(Array.from(atob(ivBase64), (c) => c.charCodeAt(0)));
          const ciphertextArray = new Uint8Array(Array.from(atob(ciphertextBase64), (c) => c.charCodeAt(0)));
          const decryptedBuffer = await crypto.subtle.decrypt(
            { name: 'AES-GCM', iv: iv },
            encryptionKey,
            ciphertextArray
          );
          const decryptedText = new TextDecoder().decode(decryptedBuffer);
          decryptedData[decryptedKey] = decryptedText;
        } else {
          decryptedData[decryptedKey] = value;
        }
      }
      return decryptedData;
    } catch (error) {
      console.error('Decryption error:', error);
      return null;
    }
  };
  const parseAndDecryptData = async (dataParam) => {
    try {
      const sanitizedDataParam = dataParam.replace(/\+/g, '%2B');
      const decodedData = decodeURIComponent(sanitizedDataParam.replace(/ /g, '+'));
      const parsedData = JSON.parse(decodedData);
      return await decryptData(parsedData);
    } catch (error) {
      console.error('Failed to parse and decrypt data parameter:', error);
      return null;
    }
  };
  export { parseAndDecryptData, encryptData };
import React from "react";
import AssetsNavbar from '../components/AssetsNavbar';
import AssetInnerBanner from '../components/assets/AssetInnerBanner';
import {Helmet} from "react-helmet";
import ElementListingAsset from "../components/assets/ElementListingAsset";
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from "../components/Footer/FooterData";



const ElementsAssetsPage = () => {
  return (
    <div className="body_wrapper">
      <AssetsNavbar slogo="sticky_logo" firstMenu="first_menu" mClass="menu_four" nClass="w_menu" blueArrow="blue_arrow" blueHomeIcon="blue_home_icon" hbtnClass="btn_get_radious menu_custfive"/>
      <AssetInnerBanner />
      <ElementListingAsset />
      <FooterSecurity FooterData={FooterData} />
      <Helmet>
                <title>A Unique Marketplace Offering Complete Software Testing Assets</title>
                <meta name="description" content="Platform Assets, Domain Assets, and Templates on FireFlink Assets, an exclusive software testing marketplace. Get your test automation project up and running in no time." />
                <meta name="keywords" content="Software Testing Assets"/>
      </Helmet>
    </div>
  );
  
 
};

      


export default ElementsAssetsPage;

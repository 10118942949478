import React, {Component} from 'react';
import HRServiceItems from '../../components/Service/HRServiceItems';
import Reveal from 'react-reveal';

class UniqueFeaturesAssets extends Component{
    render(){
        let UniqueFeaturesAssets = this.props.UniqueFeaturesAssets;
        return(
            <section className="prototype_service_info">
                
                <div className="container">
                <Reveal bottom cascade>
                    <h2 className="prodcolor text-center">Unique Features of FireFlink Assets</h2>
                </Reveal>
                    
                        <div className="head_line"></div>           
                    <div className="row p_service_info">
                            {
                                 UniqueFeaturesAssets.FireAssetsFeaturesData.map(item => {
                                    return(
                                        <HRServiceItems HRtitle={item.HRtitles} HRdescription={item.HRdescription}  Hicon={item.Hicon} rclass={item.rclass} iclass={item.iclass} featimg={item.featimg} spantext={item.spantext} HRtitle1={item.HRtitle1} key={item.id}/>
                                    )
                                })
                            }
                            {/* <div className="viewbtn">
                            <a className="white" href="">View More</a>
                            </div> */}
                    </div>

                </div>
                
            </section>
        )
    }
}
export default UniqueFeaturesAssets;
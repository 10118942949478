import React, { useEffect, useState } from "react";
import "./productPage.css";
import { verifyLicenseStatus } from "../../utils/license-utils";
import { useHistory } from "react-router-dom";
import fireFlinkFinder from "../../img/product/fireFlinkFinder.svg";
import fireFlinkPlatform from "../../img/product/fireFlinkPlatform.svg";
import tickIcon from "../../img/product/tick.svg";
import finderHover from "../../img/product/finderHover.svg";
import platformHover from "../../img/product/platformHover.svg";
import { openDeepLink } from "../../utils/openDeepLink";
import { encryptData } from "../../components/Common/EncryptDecrypt";

const DOMAIN_URL = process.env.REACT_APP_MANAGEMENT_DOMAIN_NAME;

const ProductListBanner = () => {
  const [platform, setplatform] = useState(false);
  const [finder, setFinder] = useState(false);
  const history = useHistory();

  const handlenavigation = async (product) => {
    switch (product) {
      case "Fireflink-Platform":
        let response = JSON.parse(localStorage.getItem("login-user"));
        // let data = JSON.stringify(response);
        encryptData(response)
          .then((result) => {
            console.log("result",result)
            if (verifyLicenseStatus(response)) {
              window.open(
                `${DOMAIN_URL}/projectmenu/project?from="website"&data=${result}`
              );
            } else {
              window.open(
                `${DOMAIN_URL}/licenses/fireflink-license?from="website"&data=${result}`
              );
            }
            history.push(`/`);
          })
          .catch((err) => {
            console.log("unable to encrypt", err);
          });
        // const encryptedResponse = await encrypt(response);
        // console.log(encryptedResponse);
        // if (verifyLicenseStatus(response)) {
        //   window.open(
        //     `${DOMAIN_URL}/projectmenu/project?from=website&data=${JSON.stringify(
        //       encryptedResponse
        //     )}`
        //   );
        // } else {
        //   window.open(
        //     `${DOMAIN_URL}/licenses/fireflink-license?from=website&data=${JSON.stringify(
        //       encryptedResponse
        //     )}`
        //   );
        // }
        break;
      case "Fireflink-Cloud":
        // window.open(`/fireflink-cloud`);
        history.push(`/fireflink-cloud`);
        break;
      case "Fireflink-Assets":
        // window.open(`/fireflink-assets`);
        history.push(`/fireflink-assets`);
        break;
      case "Fireflink-Finder":
        // window.open(`/fireflink-finder/#download`);call this api to open protocol deep link
        openDeepLink(
          "openfireflinkclient://",
          "/fireflink-finder/#download",
          history
        );
        break;

      default:
        break;
    }
  };

  return (
    <section>
      <div className="row d-flex justify-content-center p15 product_First_row">
        <div className="productTitle">
          <h1>Discover FireFlink's Wide Range of Offerings</h1>
          <p>
            FireFlink streamlines test automation by providing overall support,
            eliminating the need for multiple tools and dependencies.
          </p>
        </div>
      </div>
      <div className="product_sec_row ">
        <div className="container col-lg-12 col-sm-12 product_sec_row_con">
          <div className="col-lg-6 col-sm-12 col_1 d-flex flex-direction-column align-items-center">
            <div className="product_1_col">
              <div
                className="product_1"
                onClick={() => {
                  handlenavigation("Fireflink-Platform");
                }}
                onMouseOver={() => setplatform(true)}
                onMouseLeave={() => setplatform(false)}
              >
                <img
                  src={platform ? platformHover : fireFlinkPlatform}
                  alt="fireFlinkPlatform"
                />
                <p>
                  Next-gen AI-powered platform for scriptless test automation,
                  enabling 4X faster and extreme automation.
                </p>
              </div>

              <h5 className="productList mt_30">
                <span>
                  <img src={tickIcon} alt="" />
                </span>{" "}
                &nbsp; Hyper test automation
              </h5>
              <h5 className="productList mt_20">
                <span>
                  <img src={tickIcon} alt="" />
                </span>{" "}
                &nbsp; Develop scripts in simple English
              </h5>
              <h5 className="productList mt_20">
                <span>
                  <img src={tickIcon} alt="" />
                </span>{" "}
                &nbsp; A single platform for Web, Mobile, Desktop and API
                Testing
              </h5>
              <h5 className="productList mt_20">
                <span>
                  <img src={tickIcon} alt="" />
                </span>{" "}
                &nbsp; Highly Templatized reports, analytics & dashboard
              </h5>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col_2 d-flex align-items-center flex-direction-column">
            <div className="product_2_col">
              <div
                className="product_2"
                onClick={() => {
                  handlenavigation("Fireflink-Finder");
                }}
                onMouseOver={() => setFinder(true)}
                onMouseLeave={() => setFinder(false)}
              >
                <img
                  src={finder ? finderHover : fireFlinkFinder}
                  alt="fireFlinkFinder"
                />
                <p>
                  One-click UI-element locator generation. Generate multiple
                  optimal locators for a variety of apps, with a single unified
                  tool.
                </p>
              </div>
              <h5 className="productList mt_30">
                <span>
                  <img src={tickIcon} alt="" />
                </span>{" "}
                &nbsp; Unified UI Element locator tool for Web, Mobile apps
              </h5>
              <h5 className="productList mt_20">
                <span>
                  <img src={tickIcon} alt="" />
                </span>{" "}
                &nbsp; Multiple optimal locators
              </h5>
              <h5 className="productList mt_20">
                <span>
                  <img src={tickIcon} alt="" />
                </span>{" "}
                &nbsp; Relative XPaths for dynamic elements
              </h5>
              <h5 className="productList mt_20">
                <span>
                  <img src={tickIcon} alt="" />
                </span>{" "}
                &nbsp; A standalone tool for locating XPaths independently
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ProductListBanner;

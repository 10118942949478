import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonLoading from "../Common/SkeletonLoading";
import { getAllNLPAssets,getAllNLPByFilter,getAssetByCategories } from "../../api/api_services";
import { useAccordionButton } from "react-bootstrap";
import {useHistory} from "react-router-dom";
import AssetsRating from "./assets_ratings";
const NlpAssest = (props) => {
  let [NlpAssest, setNlpAsset] = useState([]);
  let [headContent, setheadContent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [activebutton, setActivebutton] = useState({
    all: true,
    web: false,
    webandmobile: false,
    android: false,
    ios: false,
  });
  const[activeTab,setactiveTab]=useState("all")
  const [pageno, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [assetsTypeID,setAssetsTypeID]=useState()
  let history=useHistory()
  useEffect(() => {
    let typeId;
    let assetstype=localStorage.hasOwnProperty("assets-type")&&JSON.parse(localStorage.getItem("assets-type"));
    assetstype.map((assets)=>{
      if(assets.type==="Nlps"){
        typeId=assets.id
      }
    })
    getAllNLP(typeId);

  }, []);
  console.log(NlpAssest);
  const getAllNLP = async (typeId) => {
    setAssetsTypeID(typeId)
    console.log("assetsTypeID",typeId)
    let response=await getAssetByCategories(typeId,pageno,pageSize)
    console.log("getAllNLP",response)
    if (response?.data?.responseCode === 200 && response?.data?.message==="SUCCESS") {
      setNlpAsset(response?.data?.responseObject?.content);
      setIsLoading(false);
    } else {
      setNlpAsset([]);
      setIsLoading(false);
    }
  };

  const getNLPByFilter=async(filter)=>{
    let response=await getAllNLPByFilter(filter,pageno,pageSize,assetsTypeID);
    if (response?.data?.responseCode === 200 && response?.data?.message==="SUCCESS") {
      setNlpAsset(response?.data?.responseObject?.content);
     
    } else {
      setNlpAsset([]);
     
    }
  }
  const handleActivebutton = (key) => {
    switch (key) {
      case "all": {
        setActivebutton({
          all: true,
          web: false,
          webandmobile: false,
          android: false,
          ios: false,
        });
        setactiveTab("all")
        getAllNLP(assetsTypeID)
        break;
      }
      case "web": {
        setActivebutton({
          all: false,
          web: true,
          webandmobile: false,
          android: false,
          ios: false,
        });
        setactiveTab("web");
        getNLPByFilter("Web")
        break;
      }
      case "webandmobile": {
        setActivebutton({
          all: false,
          web: false,
          webandmobile: true,
          android: false,
          ios: false,
        });
        setactiveTab("webandmobile")
        getNLPByFilter("Web_And_Mobile")
        break;
      }
      case "android": {
        setActivebutton({
          all: false,
          web: false,
          webandmobile: false,
          android: true,
          ios: false,
        });
        setactiveTab("android")
        getNLPByFilter("Android")
        break;
      }
      case "ios": {
        setActivebutton({
          all: false,
          web: false,
          webandmobile: false,
          android: false,
          ios: true,
        });
        setactiveTab("ios")
        getNLPByFilter("iOS")
        break;
      }
      default: {
        setActivebutton({
          all: true,
          web: false,
          webandmobile: false,
          android: false,
          ios: false,
        });
        setactiveTab("all")
        getAllNLP(assetsTypeID)
        break;
      }
    }
  };
  return (
    <section className="pb_30">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="asset_head_two pt-50">
              {/* {isLoading ? <Skeleton width={300} /> : headContent[1]?.section} */}
              {isLoading ? <Skeleton width={300} /> : props?.data?.nlpHeader}
            </h2>
            <div className="asset_head_line ml-70"></div>
          </div>
          <div className="col-lg-6">
            <div className="item_type pt-50">
              <div
                className={
                  activebutton.all
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("all");
                }}
              >
                All items
              </div>
              <div
                className={
                  activebutton.web
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("web");
                }}
              >
                Web
              </div>
              <div
                className={
                  activebutton.webandmobile
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("webandmobile");
                }}
              >
                Web & Mobile
              </div>
              <div
                className={
                  activebutton.android
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("android");
                }}
              >
                Android
              </div>
              <div
                className={
                  activebutton.ios
                    ? "items_type_asset_active"
                    : "items_type_asset"
                }
                onClick={() => {
                  handleActivebutton("ios");
                }}
              >
                iOS
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p className="asset_head_des">
              {isLoading ? (
                <Skeleton width={900} />
              ) : (
                // headContent[1]?.description 
                props?.data?.nlpDescription
              )}
            </p>
          </div>
        </div>
        <div className="row pr_40 pl_40">
          {isLoading ? (
            <>
              <div>
                {" "}
                <SkeletonLoading itemCount={4} />
              </div>
            </>
          ) : (
            <>
              {NlpAssest?.slice(0, 7).map((data) => (
                <div className="col-lg-3 pl-30 pr_30 mt_50">
                  <div className="used_asset_box" onClick={()=>{history.push(`/nlp's-list?id=${data.id}&title=${data?.title}`)}}>
                    <div className="sce_btn">
                      <div className="asset_type">
                        {
                          data?.title==="Excel"?(
                            <>
                             <img
                          src={data?.iconS3Path}
                          alt="excel"
                        />
                            </>
                          ):(<>
                         
                           <img
                          src={require("../../img/assets/excel.svg").default}
                          alt="excel"
                        />
                          </>)
                        }
                       
                      </div>
                    </div>
                    <h3 className="mt-2 asset_type_head">{data.title}</h3>
                    <h4 className="asset_des ml-3 mr-3 mt_20">
                      {data.shortDescription}
                    </h4>
                    <div className="ml-3 mt-1 star_rating">
                      <AssetsRating rating={data?.rating}/>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}



          {
            isLoading?(
              <>
              <div className="col-lg-3 pl-30 pr_30 mt_50">
            <div className="">
            <SkeletonLoading itemCount={1} />
            </div>
          </div>
              </>
            ):(
              <>
              {
                NlpAssest?.length>7 && (
                  <div className="col-lg-3 pl-30 pr_30 mt_50">
            <div className="used_asset_box" onClick={()=>{history.push(`/nlp's?key=${activeTab}`)}}>
              <div className="text-center">
                <div>
                  <h3 className="view_all_txt mt_60">View All</h3>
                </div>
              </div>
            </div>
          </div>
                )
              }
              </>
              
              
            )
          }
         
          {/* <div className="col-lg-3 pl-30 pr_30 mt_50">
            <div className="used_asset_box" >
          {isLoading ? (<><div>
                  {" "}
                  <SkeletonLoading itemCount={4} />
                </div></>): <><div className="col-lg-3 pl-30 pr_30 mt_50">
            <div className="used_asset_box" onClick={()=>{history.push(`/nlp-assets?key=${activeTab}`)}}>
              <div className="text-center">
                <div>
                <h3 className="view_all_txt mt_60">View All</h3>
                </div>
              </div>
            </div>
          </div></>}
          
        </div>
      </div> */}



    </div>
    </div>
    </section>
  );
};

export default NlpAssest;

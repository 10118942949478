import React, { useState } from "react";
import VideoNavSideBar from "../components/video-tutorial/VideoNavSideBar";
import VideoBodyWrapper from "../components/video-tutorial/VideoBodyWrapper";

import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";

import styled from "styled-components";
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from "../components/Footer/FooterData";

const PreRequitesLayout = ({ children }) => {
  const [sideNavState, setSideNavState] = useState([]);
  const Wrapper = styled.aside`
    background: #ccc;
    width: 300px;
  `;

  const menus = [
    {
      label: "Cloud Version",
      path: "/prerequisites/cloud-version",
      icon: <img src={require("../img/pre-req/cloud-version.svg").default} alt="cloud-version" />,
    },
    {
      label: "On Premise Version",
      path: "/prerequisites/on-premise-version",
      icon: <img src={require("../img/pre-req/onpremise-version.svg").default} alt="onpremise-version" />,
    },
    {
      label: "Safari Browser Limitation",
      path: "/prerequisites/safari-browser-automation-limitations",
      icon: <img src={require("../img/pre-req/safari-browser.svg").default} alt="Safari Browser" />,
    },
    
   
  ];
  return (
    <>
      <CustomNavbar
        mClass="menu_eight"
        nClass="w_menu"
        slogo="sticky_logo"
        blueArrow="blue_arrow"
        hbtnClass="security_btn"
      />
      <Breadcrumb
        BannercssClass="backbg_css"
        BannerimgClass="pre_req_banner"
        Ptitle="Pre Requisites"
        Pdescription="Below are the details that are to be considered if the customer is opting for FireFlink Platform – Cloud and Onpremise version"
      />

      <section className="mt_40 mb-30">
        <div className="container">
          <div className="video_left_layout">
            <div className="row">
              <div className="col-lg-3 mt_20 mb_70">
                <VideoNavSideBar menus={menus} />
              </div>
              <div className="col-lg-9 ml_40 right_line overflow-hidden">
                <VideoBodyWrapper>
                  <main className="content">{children}</main>
                </VideoBodyWrapper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterSecurity FooterData={FooterData} />
    </>
  );
};
export default PreRequitesLayout;

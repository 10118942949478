import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonLoading from "../Common/SkeletonLoading";
import { getMostUsedNLPAssets } from "../../api/api_services";
import { useHistory } from "react-router-dom";
import AssetsRating from "./assets_ratings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MostUsedAssets = (props) => {
  console.log("MostUsedAssets", props);
  // mostUsedHeader,mostUsedDescription
  let [mostUsed, setMostUsed] = useState([]);
  let [headContent, setheadContent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();
  const [isEndOfSection, setIsEndOfSection] = useState(false);
  const [isStartOfSection, setIsStartOfSection] = useState(true);
  const sliderRef = useRef(null);

  useEffect(() => {
    getallassets();
  }, []);

  const getallassets = async () => {
    let response = await getMostUsedNLPAssets();
    if (response?.data?.responseCode === 200) {
      setMostUsed(response?.data?.responseObject);
      setIsLoading(false);
    } else {
      setMostUsed([]);
      setIsLoading(false);
    }
    console.log("nlp output", response);
  };
  const settings = {
    // infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    arrows: false,
    beforeChange: (current, next) => {
      setIsStartOfSection(next === 0);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const slidePrev = () => {
    sliderRef.current.slickPrev();
    setIsEndOfSection(false);
  };
  const slideNext = () => {
    sliderRef.current.slickNext();
    setIsStartOfSection(false);
  };
  const handleAfterChange = (currentSlide) => {
    console.log("currentSlide", currentSlide, sliderRef.current);
    setIsEndOfSection(currentSlide === 4);
  };

  console.log(headContent);
  console.log(headContent[0] && headContent[0].section);

  const handlenavigation = (data) => {
    let key = "";
    let assetstype =
      localStorage.hasOwnProperty("assets-type") &&
      JSON.parse(localStorage.getItem("assets-type"));
    assetstype.map((assets) => {
      if (assets.id === data?.typeId) {
        key = assets.type;
      }
    });

    switch (key) {
      case "Nlps":
        history.push(`/nlp's-list?id=${data.id}&title=${data.title}`);
        break;
      case "Elements":
        // history.push(`/elements-description?id=${data.id}`);
        history.push(`/elements-list?id=${data.id}`)
        break;
      case "StepGroups":
        // history.push(`/step-group-description?id=${data.id}`);
        history.push(`/stepgroup-list?id=${data.id}`)
        break;
      default:
        break;
    }
  };
  return (
    <SkeletonTheme baseColor="#eee" highlightColor="#FFFFFF">
      <section className="most_used_assets pb_30">
        <div className="container">
          <h2 className="asset_head_two">
            {/* {isLoading ? <Skeleton width={300} /> : headContent[0]?.section} */}
            {isLoading ? <Skeleton width={300} /> : props?.data?.mostUsedHeader}
          </h2>
          <div className="asset_head_line ml-150"></div>
          <p className="asset_head_des">
            {isLoading ? (
              <Skeleton width={900} />
            ) : (
              // headContent[0]?.description
              props?.data?.mostUsedDescription
            )}
          </p>

          <div>
            <Slider
              {...settings}
              className="cardSection"
              ref={sliderRef}
              afterChange={handleAfterChange}
            >
              {mostUsed?.map((data, index) => {
                return (
                  <div className="col-lg-3 pl_50 mb-3 pr_30 mt_30">
                    <div
                      className="most_used_asset_box"
                      onClick={() => {
                        handlenavigation(data);
                        // history.push("/excel");
                      }}
                    >
                      <div className="sce_btn">
                        <div className="asset_type">
                          <img
                            src={require("../../img/assets/excel.svg").default}
                            alt="excel"
                          />
                        </div>
                      </div>

                      <h3 className="mt-2 asset_type_head">{data.title}</h3>

                      <h4 className="asset_des ml-3 mr-3 mt-2">
                        {data.shortDescription}
                      </h4>
                      <div className="ml-3 star_rating">
                        <AssetsRating rating={data?.rating} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <button
              className="back_slide b_none"
              onClick={slidePrev}
              style={{ opacity: isStartOfSection ? "0.5" : "1" }}
              disabled={isStartOfSection}
            >
              <img src={require("../../img/assets/back.svg").default} />
            </button>
            <button
              className="forward_slide fr b_none"
              onClick={slideNext}
              style={{ opacity: isEndOfSection ? "0.5" : "1" }}
              disabled={isEndOfSection}
            >
              <img src={require("../../img/assets/forward.svg").default} />
            </button>
          </div>

          {/* <div className="row pr_40 pl_40">
            {isLoading ? (
              <>
                <div>
                  {" "}
                  <SkeletonLoading itemCount={4} />
                </div>
              </>
            ) : (
              <>
                {mostUsed?.slice(4, 8).map((data, index) => (
                  <div className="col-lg-3 pl-30 pr_30 mt_30">
                    <div
                      className="used_asset_box"
                      onClick={() => {
                        history.push("/excel");
                      }}
                    >
                      <div className="sce_btn">
                        <div className="asset_type">
                          <img
                            src={require("../../img/assets/excel.svg").default}
                            alt="excel"
                          />
                        </div>
                      </div>

                      <h3 className="mt-2 asset_type_head">{data.title}</h3>

                      <h4 className="asset_des ml-3 mr-3 mt-2">
                        {data.shortDescription}
                      </h4>
                      <div className="ml-3 star_rating">
                        <AssetsRating rating={data?.rating} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div> */}
        </div>
      </section>
    </SkeletonTheme>
  );
};

export default MostUsedAssets;

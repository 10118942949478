import React, { useEffect } from "react";
import ProductsNavbar from "./productsNavbar";
import ProductListBanner from "./productListBanner";
import { useHistory } from "react-router-dom";
const ProductsPage=()=>{
    const history = useHistory();
  
    useEffect(()=>{
        if(!localStorage.hasOwnProperty("login-user")){
            history.push("/")
        }
    },[])
    return(
       <>
      <div className="body_wrapper">
       <ProductsNavbar
        mClass="menu_eight"
        nClass="w_menu"
        slogo="sticky_logo"
        blueArrow="blue_arrow"
        hbtnClass="security_btn"
        headerClass="position"
      />
    <ProductListBanner/>
    </div>
       </>
       
       
    )
}
export default ProductsPage
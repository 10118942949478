import React, {Component} from 'react';

class HowCloudWorks extends Component {
    render(){
        
        return(
            <section className="whychoose_finder">
                <div className="container">    
                <h2 className="mt_40 headtwo">Why Choose FireFlink Cloud?</h2>
                
                <div className="sce_btn">
                        <div className="head_line"></div>
                        </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <img className="w-100" src={require("../../img/cloud/how-firecloud-works.svg").default} alt="How FireFlinkcloud works" />
                        </div>
                    </div>
                         
                    
                </div>    
            </section>
        )
    }
}

export default HowCloudWorks;
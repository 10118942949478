import { useEffect, useState } from "react";
import logo from "../../img/home/floatLogo.svg"
import closeIcon from "../../img/home/closeIcon.svg"
import { Link } from "react-router-dom";

function FloatingDemo() {
  const [isShown, setisShown] = useState(false);
  useEffect(() => {
    // Check if the user has already closed the popup
    const popupShown = sessionStorage.getItem("popupShown");
    if (popupShown) {
      setisShown(false);
    } else {
      setisShown(true);
      sessionStorage.setItem("popupShown", "true");
    }
    console.log("rendereing")
  }, []);
  console.log("isShown",isShown);
  return (
    <div className="floatingDemo">
      {isShown && (
        <div className="floatingContent" >
         <button className="closeIcon" onClick={()=>{setisShown(false)}}  ><img src={closeIcon} alt="Close Icon" className="closeIcon" /></button> 
        <Link className="floatingButton" to="/schedule-demo" >
         <img src={logo} alt="GET ADEMO" /> Get a Demo
        </Link>
          </div>
      )}
    </div>
  );
}

export default FloatingDemo;

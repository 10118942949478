import httpConnection from "./httpconnection";
const BASE_URL = process.env.REACT_APP_MANAGEMENT_BASE_URL;
const ASSETS_URL = process.env.REACT_APP_ASSETS_BASE_URL;

export const signInSiteKey = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/site-key`, payload);
export const isEmailExistRequest = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/email/exists`, payload);
export const defaultCountry = () => httpConnection.get(`https://api.country.is/`);
export const signUpRequest = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/signup`, payload);
export const resetActivationLink = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/activation/resend`, payload);
export const validateUserToken = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/validate/user-token`, payload);
export const activateEmail = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/activation/activate`, payload);
export const changeActivePhoneNumber = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/change-phone`, payload);
export const signInRequest = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/signin`, payload);
export const forgotPasswordRequest = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/send-change-pwd-link`, payload);
export const changePasswordRequest = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/public/user/change-password`, payload);
export const signOutReq = (payload) => httpConnection.post(`${BASE_URL}/optimize/v1/user/signout`, payload);

//Download Count API

export const IncrementDownlaodCount = (payload) =>
  httpConnection.post(
    `https://backend1.fireflink.com/appmanagement/optimize/v1/public/clientDownloads/create`,
    payload
  );

export const getTotalDownlaodCount = () =>
  httpConnection.get(
    `https://backend1.fireflink.com/appmanagement/optimize/v1/public/clientDownloads/getClientDownloadsCount`
  );

// Contact us and Schedule demo API

export const ContactUs = (payload) =>
  httpConnection.post(
    `http://testbackend.fireflink.com/appmanagement/optimize/v1/public/customer/request?emailType=contact_us`,
    payload
  );

export const ScheduleDemo = (payload) =>
  httpConnection.post(
    `http://testbackend.fireflink.com/appmanagement/optimize/v1/public/customer/request?emailType=demo_request`,
    payload
  );


  //Assets API
export const getAllAssetsType=()=>httpConnection.get(`${ASSETS_URL}/v1/public/assets-types`)
export const getMostUsedNLPAssets=()=>httpConnection.get(`${ASSETS_URL}/v1/public/categories/most-used?page=0&size=16`);
export const getAssetByCategories=(typeid,page,size)=>httpConnection.get(`${ASSETS_URL}/v1/public/categories/type?typeId=${typeid}&page=${page}&size=${size}`)
export const getAllNLPAssets=(page,size)=>httpConnection.get(`${ASSETS_URL}/v1/public/categories?page=${page}&size=${size}`);
export const getNLPCategoriesbyID=(id)=>httpConnection.get(`${ASSETS_URL}/v1/public/categories/${id}`);
export const getNLPCategoriesDetailsByTitle=(title,typeId)=>httpConnection.get(`${ASSETS_URL}/v1/public/sub-categories/by-title?title=${title}&typeId=${typeId}`);
export const getAllNLPByFilter=(filter,page,size,typeId)=>httpConnection.get(`${ASSETS_URL}/v1/public/categories/filter?assetsTag=${filter}&page=${page}&size=${size}&typeId=${typeId}`);
export const getNLPDescriptionById=(id)=>httpConnection.get(`${ASSETS_URL}/v1/public/nlp-details?nlpId=${id}&description=true`);
export const getNLPScenarios=(title,size)=>httpConnection.get(`${ASSETS_URL}/v1/public/nlp-details/scenarios?title=${title}&size=${size}`);
export const getNLPQustionAndAnswers=(title,size)=>httpConnection.get(`${ASSETS_URL}/v1/public/nlp-details/faqs?title=${title}&size=${size}`);
export const getAssetsData=()=>httpConnection.get(`${ASSETS_URL}/v1/public/web-site`);
export const getStepGroupByFilter=(title,pageno,size)=>httpConnection.get(`${ASSETS_URL}/v1/public/step-group/filter?title=${title}&page=${pageno}&size=${size}`)
export const getElementsByFilter=(title,assetstag,pageno,size)=>httpConnection.get(`${ASSETS_URL}/v1/public/elements/filter?title=${title}&assetsTags=${assetstag}&page=${pageno}&size=${size}`)
export const getNLPList=(title,typeId)=>httpConnection.get(`${ASSETS_URL}/v1/public/nlp-details/title/type-id?title=${title}&typeId=${typeId}`);
export const getAssetsBySearch=(payload)=>httpConnection.post(`${ASSETS_URL}/v1/public/categories/search`,payload);
export const getNLPlistBySearch=(payload)=>httpConnection.post(`${ASSETS_URL}/v1/public/nlp-details/search`,payload);
export const getListofElementsOrStepGoup=(title,typeID)=>httpConnection.get(`${ASSETS_URL}/v1/public/sub-categories/short-description?title=${title}&typeId=${typeID}`);
export const getDetailsOfElementsOrStepGoup=(assetsType,assetsId)=>httpConnection.get(`${ASSETS_URL}/v1/public/details/by-id?id=${assetsId}&type=${assetsType}`)


import React, {useState } from "react";
import Rating from '@mui/material/Rating';

const AssetsRating=(props)=>{
    console.log("AssetsRating",props)
    let[ratingvalue,setRatingValue]=useState(props?.rating)
    return(
        <>
        <Rating  defaultValue={ratingvalue} size="small" readOnly  precision={0.5}/>
        </>
    )
    

}
export default AssetsRating;

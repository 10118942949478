import React, { useState, useEffect } from "react";
import AssetsRating from "./assets_ratings";
import NodataFound from "./nodataFound";

const AssetResult = (props) => {
  let [assetsList, setAssetsList] = useState(props?.assetslist);

  return (
    <section className="pb_30">
      <div className="container">
        {assetsList?.length > 0 ? (
          <div
            className="row pr_40 pl_40 scroll_container"
            onScroll={props?.handlescroll}
          >
            {assetsList?.map((data, index) => (
              <div className="col-lg-3 pl-30 pr_30 mt_60">
                <div className="used_asset_box">
                  <div className="sce_btn">
                    <div className="asset_type">
                      <img
                        src={require("../../img/assets/excel.svg").default}
                        alt="excel"
                      />
                    </div>
                  </div>

                  <h3 className="mt-2 asset_type_head">{data.title}</h3>

                  <h4 className="asset_des ml-3 mr-3 mt-2">
                    {data.shortDescription}
                  </h4>
                  <div className="ml-3 star_rating">
                    <AssetsRating rating={data?.rating} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="nodatafoun-container">
            <NodataFound />
          </div>
        )}
      </div>
    </section>
  );
};

export default AssetResult;

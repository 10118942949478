import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const RelatedNlp = (props) => {
  const [nlplist,setNlplist]=useState(props?.data);
  let history = useHistory();
  const search = window.location.search;
  const id = new URLSearchParams(search).get("id");
  return (
    <section className="most_used_assets pb_30">
      <div className="container">
        <h2 className="pt-50 extract_head ml_60">{props?.label==="elements"?"Related Elements":props?.label==="stepgroup"?"Related Step Group":"Related Nlp’s"}</h2>

        <div className="asset_head_line ml-0"></div>
        <div className="asset_list_line_grey w100"></div>
        {
         ["elements","stepgroup"].includes(props?.label)?( <div className="row">
          {
            nlplist?.map((item)=>{
              if(item._id!==id){
                return (
                  <div className="col-lg-6 ">
              <div className="extract_box mt-4 d-flex">
                <div className="col-lg-10">
                  <h3 className="f_600 f_size_12 f_p mt-3">
                   {item?.name}
                  </h3>
                  <p className="f_normal f_size_10 f_r mt-2 mb-2">
                   {item?.shortDescription}
                  </p>
                </div>
                <div className="col-lg-2">
                  <button className="details_btn mt-4"  onClick={() => {
                
                      props?.hadleRerender(item?._id)
                     
                    }}>Details</button>
                </div>
              </div>
             
            </div>
                )
              }
             
            })
          }
          
          
        </div>):(<div className="row">
          {
            nlplist?.map((item)=>{
              if(item._id!==id){
                return (
                  <div className="col-lg-6 ">
              <div className="extract_box mt-4 d-flex">
                <div className="col-lg-10">
                  <h3 className="f_600 f_size_12 f_p mt-3">
                   {item?.nlpName}
                  </h3>
                  <p className="f_normal f_size_10 f_r mt-2 mb-2">
                   {item?.shortDescription}
                  </p>
                </div>
                <div className="col-lg-2">
                  <button className="details_btn mt-4"  onClick={() => {
                
                      props?.hadleRerender(item?._id)
                     
                    }}>Details</button>
                </div>
              </div>
             
            </div>
                )
              }
            
            })
          }
          
          
        </div>)
        }
        
       
      </div>
    </section>
  );
};

export default RelatedNlp;

import React, { useEffect, useState } from "react";
import {
  getElementsByFilter,
  getStepGroupByFilter,
  getListofElementsOrStepGoup
} from "../../api/api_services";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import NodataFound from "./nodataFound";


const ProjectElements = (props) => {
  const [title, setTitle] = useState(props?.title);
  const [typeID,setTypeID]=useState(props?.typeId);
  const [DataList,setDataList]=useState([]);
  const [list,setList]=useState([]);
  const [expandNlpList,setExpandMoreNlpList]=useState(false)
  let history = useHistory();
  const[searchData,setSearchData]=useState("")
  useEffect(() => {
    HandelListofElemntsOrStepGroup()
  }, [props]);
  const HandelListofElemntsOrStepGroup=()=>{
    getListofElementsOrStepGoup(title,typeID).then((response)=>{
      console.log(response)
      if(response?.data?.responseCode === 200 && response?.data?.message === "SUCCESS"){
        setDataList(response?.data?.responseObject);
        let tempdata=response?.data?.responseObject.slice(0,10)
        setList(tempdata)
      }else{
        setDataList([]);
        setList([])
      }
                   
    }).catch(()=>{
      console.log("Error")
    })
  }
  const getMoreAssetsList = (state) => {
    if(state){
      setExpandMoreNlpList(false);
      let temp=DataList?.slice(0, 10)
      setList(temp);
    }else{
      setExpandMoreNlpList(true);
      setList(DataList);
    }
    
  };
  
  const handleNavigation =(Id)=>{
    if(props?.category === "Elements"){
      history.push(`/elements-description?id=${Id}&type=elements`);
    }else{
      history.push(`/step-group-description?id=${Id}&type=step_group`)
    }
  }

  const handleSearch=(e)=>{
    setSearchData(e.target.value)
    if(e.target.value.length===0){
      setSearchData(e.target.value)
      let tempdata = DataList.slice(0, 10);
      setList(tempdata);
  }
  }
  const handleEnterKeyPress=(event)=>{

    if (event.key === 'Enter') {
      if(searchData.length===0){
        let tempdata = DataList.slice(0, 10);
        setList(tempdata);
      }else{
       
        handleSearchdata(searchData);
        
      }
    }
  };

  const handleSearchdata = (value) => {
    let searchValue = value;
    if (searchValue) {
      let tempSimilarArray = [];
      DataList.map((proVal, index) => {
        let name = proVal?.name;
        let description = proVal?.shortDescription;

        let similarExistname = name?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistDescription = description?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );

        if (similarExistname || similarExistDescription) {
          tempSimilarArray.push(proVal);
           setList(tempSimilarArray);
        }
        
      });
      if(tempSimilarArray?.length===0){
        setList([]);
      }
    } else {
      let tempdata = DataList.slice(0, 10);
      setList(tempdata);
    }
  };
 
  return (
    <section className="most_used_assets pb_30">
      <div  className="container">

     
        <div className="row">
              <div className="col-lg-6">
                <h2 className="pt-50 extract_head upper_case ml-15">{props?.category === "Elements"
                ? "Project Elements"
                : "Step Groups"}</h2>
              </div>
              <div className="col-lg-6 pt-50">
                <div className="fr">
                  <TextField
                    //label="With normal TextField"
                    placeholder="Search"
                    variant="standard"
                    // onChange={(e) => {
                    //    handleSearch(e);
                    // }}
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    onKeyDown={handleEnterKeyPress}
                    autoComplete="off"
                    sx={{
                      width: "260px",
                      height: "26px",
                      border: "1px solid #DB7221",
                      background: "#DB722126",
                      borderRadius: "26px",
                      "& .MuiInput-underline:before": { display: "none" },
                      "& .MuiInput-underline:after": { display: "none" },
                      "& input": { padding: "0px", marginLeft: "4px" },
                      padding: "0px",
                      paddingLeft: "5px",
                      fontSize: "8px",
                    }}
                    InputProps={{
                      fontSize: "30px",
                      endAdornment: (
                        <InputAdornment className="assest_type_search_txt">
                          <IconButton className="nlp_search_btn">
                            <SearchIcon className="assest_type_search_icon" onClick={()=>{handleSearchdata(searchData)}}/>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              
              <div className="asset_head_line ml-3"></div>
              <div className="asset_list_line_grey w100"></div>
            </div>
            {
              list?.length>0 ?( <div className="row">
              {list?.map((assets) => {
                return (
                  <div className="col-lg-6 ">
                    <div className="extract_box ml-15 mt-4 d-flex">
                      <div className="col-lg-10">
                        <h3 className="f_600 f_size_12 f_p mt-3">
                          {assets?.name}
                        </h3>
                        <p className="f_normal f_size_10 f_r mt-2 mb-2">
                          {assets?.shortDescription}{" "}
                        </p>
                      </div>
                      <div
                        className="col-lg-2"
                        onClick={() => {
                          handleNavigation(assets?._id)
                        
                        }}
                      >
                        <button className="details_btn mt-4">Details</button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>):(
               <div className="nodatafoun-container mt_15">
              <NodataFound/>
             </div>
            )
            }
           
            {
              list?.length>10 && (<div className="text-center mt_30">
              <button className="explore_more" onClick={()=>{getMoreAssetsList(expandNlpList)}}>
                {expandNlpList?"View less Nlp's":"Explore More Nlp’s"}
              </button>
            </div>)
            }
             </div>
    </section>
  );
};

export default ProjectElements;

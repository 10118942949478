import React, { Component } from "react";
import Reveal from "react-reveal";

class Explore extends Component {
  render() {
    return (
      <section className="explore">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <div className="exploretext  text-center mt_40">
              <Reveal bottom cascade>
                <h2>Are you ready to Explore?</h2>

                <p>
                  Discover how FireFlink's products work by letting our team
                  experts walk you through it.
                </p>
                <div className="sce_btn">
                <a
                  className="home_banner_btn_hover home_banner_btn"
                  href="/schedule-demo"
                >
                  Schedule a Demo
                </a>
                </div>
              </Reveal>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Explore;

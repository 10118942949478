import React from "react";
import AssetsNavbar from '../components/AssetsNavbar';
import FireAssetsBanner from "../components/Banner/FireAssetsBanner";
import {Helmet} from "react-helmet";
import ExcelDetail from "../components/assets/ExcelDetail";
import AssetDescription from "../components/assets/AssetDescription";
import SupportedVideo from "../components/assets/SupportedVideo";
import AssetResult from "../components/assets/AssetResult";
import AssetResultCategory from "../components/assets/AssetResultCategory";
import FooterSecurity from "../components/Footer/FooterSecurity";
import FooterData from "../components/Footer/FooterData";
import GetStarted from "../components/GetStarted";
import Explore from "../components/Explore";


const AssetSearchResultPage = () => {
  return (
    <div className="body_wrapper">
      <AssetsNavbar slogo="sticky_logo" firstMenu="first_menu" mClass="menu_four" nClass="w_menu" blueArrow="blue_arrow" blueHomeIcon="blue_home_icon" hbtnClass="btn_get_radious menu_custfive"/>
      <FireAssetsBanner />
      <AssetResult />
      <AssetResultCategory />
      <GetStarted/>
      <Explore />
      <FooterSecurity FooterData={FooterData} />
      

      <Helmet>
                <title>A Unique Marketplace Offering Complete Software Testing Assets</title>
                <meta name="description" content="Platform Assets, Domain Assets, and Templates on FireFlink Assets, an exclusive software testing marketplace. Get your test automation project up and running in no time." />
                <meta name="keywords" content="Software Testing Assets"/>
      </Helmet>
    </div>
  );
  
 
};

      


export default AssetSearchResultPage;

import React from "react";
import { Link } from "react-router-dom";
const SignInButton = ({path , isHeader}) => {
  return (
    <>
      <div className="">
        <Link  to={path} className={isHeader? "sign_in btn btn-outline-primary":"sign_in_orange btn btn-outline-primary"}>
          Sign In
        </Link>
      </div>
    </>
  );
};

export default SignInButton;

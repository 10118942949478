import React from "react";
import { Link } from "react-router-dom";
const GetDemoNavButton = () => {
  return (
    <>
      <div className="">
      <Link to="/schedule-demo" className="get_demo_nav_btn btn btn-primary">
          Get a Demo
        </Link>
      </div>
    </>
  );
};

export default GetDemoNavButton;

import featimg1 from "../../img/home/hyper.png";
import nlp from "../../img/home/templatized.png";
import agile from "../../img/home/learnablity.png";
import devops from "../../img/home/hire-engineers.png";
import template from "../../img/home/compatibility.png";
import scriptnlp from "../../img/firelink-platform/script-nlp.png";
import usability from "../../img/home/usability.png";

const AssetsFeaturesData = {

    FireAssetsFeaturesData: [
    {
      id: "1",
      HRtitles: "Hyper",
      HRtitle1: "Automation",

      HRdescription:
        "A well-designed plan to automate a mid-sized application with 500 test cases on web, android, iOS or API in under 10 days using a high-speed test automation platform . Reduces automation efforts from years to days.",
      rclass: "",
      iclass: "icon",
      featimg: featimg1,
    },
    {
      id: "2",
      HRtitles: "Templatized ",
      HRtitle1: "platform",
      // spantext: '(Natural Language Processing)',
      HRdescription:
        "In addition to the standard report format, FireFlink offers a plug-and-play architecture with customizable report, dashboard and analytics templates.",
      Hicon: "layout-grid2",
      rclass: "",
      iclass: "icon",
      featimg: nlp,
    },
    {
      id: "3",
      HRtitles: "Effortless",
      HRtitle1: "Learnability",
      HRdescription:
        "Everyone can learn this platform in as little as three to five days. In reality, the platform's objective is to empower manual test engineers to automate using simple English statements, making automation testing accessible to everybody.",
      Hicon: "fullscreen",
      rclass: "",
      iclass: "icon",
      featimg: agile,
    },
    {
      id: "4",
      HRtitles: "Ready to",
      HRtitle1: "Hire engineers ",
      HRdescription:
        "There is a ready-made workforce of over 8,000 certified engineers in India, the United States, Canada and the United Kingdom. As a result, it is simpler than ever to assemble a team of skilled engineers.",
      Hicon: "vector",
      rclass: "",
      iclass: "icon",
      featimg: devops,
    },
    {
      id: "5",
      HRtitles: "Hassle free",
      HRtitle1: "compatibility tests",

      HRdescription:
        "Better insights on the app's behaviour through the execution of test automation suites  on multiple platforms, including OSs, browsers and mobile devices. FireFlink Cloud costs 15% less than other cloud providers.",
      Hicon: "bolt",
      rclass: "",
      iclass: "icon",
      featimg: template,
    },
    {
      id: "6",
      HRtitles: "Scriptless - NLP",
      HRtitle1: "Techniques",

      HRdescription:
        "With FireFlink, writing automation test scripts is as simple as writing manual test cases. With 95% of the automation requirements met by NLP, simply input an action or a verification statement and begin developing the test script.",
      Hicon: "bolt",
      rclass: "",
      iclass: "icon",
      featimg: scriptnlp,
    },
  ],
}
export default AssetsFeaturesData;
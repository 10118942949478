import React from "react";
import securdenLogo from "../../img/home/happy-clients/securden.png";
import theHinduLogo from "../../img/home/happy-clients/thehindu.png";
import hussmannLogo from "../../img/home/happy-clients/hussmann.png";
import jopLogo from "../../img/home/happy-clients/jop.png";
import starllyLogo from "../../img/home/happy-clients/starlly.png";
import gridLogo from "../../img/home/happy-clients/grid.png";
import motilalOswalLogo from "../../img/home/happy-clients/motilal-oswal.png";
import alchemyLogo from "../../img/home/happy-clients/alchemy.png";
import coderythmLogo from "../../img/home/happy-clients/coderythm.png";
import imsLogo from "../../img/home/happy-clients/ims.png";
import paymentzLogo from "../../img/home/happy-clients/paymentz.png";
import nimbblLogo from "../../img/home/happy-clients/nimbbl.png";
import kukufmLogo from "../../img/home/happy-clients/kukufm.png";
import toptechLogo from "../../img/home/happy-clients/toptech.png";
import abymTechnologyLogo from "../../img/home/happy-clients/abym-technology.png";
import xperimentorLogo from "../../img/home/happy-clients/xperimentor.png";
import quiloLogo from "../../img/home/happy-clients/quilo.png";
import PolicyAdvisor from "../../img/home/happy-clients/policyadvisor.svg";
import swastikaLogo from "../../img/home/happy-clients/swastika.png";
import ncdex from "../../img/home/happy-clients/NCDEX.png";
import Zype from "../../img/home/happy-clients/zype-logo.png";
import AaronTechLogo from "../../img/home/happy-clients/Testimonial/aaran-tech.svg";

const HappyClients = () => {
  // Array of logo images
  const logos = [
    ncdex,
    securdenLogo,
    theHinduLogo,
    hussmannLogo,
    jopLogo,
    starllyLogo,
    gridLogo,
    motilalOswalLogo,
    alchemyLogo,
    coderythmLogo,
    imsLogo,
    paymentzLogo,
    nimbblLogo,
    kukufmLogo,
    toptechLogo,
    abymTechnologyLogo,
    xperimentorLogo,
    quiloLogo,
    PolicyAdvisor,
    swastikaLogo,
    Zype,
    AaronTechLogo,
  ];

  // Duplicate the logos array to create a continuous loop effect
  const doubledLogos = [...logos];

  // Keep adding logos to the doubledLogos array until it reaches a sufficient length
  while (doubledLogos.length < 1000) {
    // Set a large enough number to ensure virtually infinite scrolling
    doubledLogos.push(...logos);
  }
  // Duplicate the logos array to create a continuous loop effect
  // const doubledLogos = [...logos, ...logos, ...logos, ...logos];

  return (
    <section className="mb_60 mt_80 happy-clients">
      <div className="clients_background">
        <div className="border_line">
          <div className="headLineDiv">
            <div className="orangeHeadLine"></div>
          </div>
          <h2 className="clients_title">
            Helping Leading Brands Release Software Faster
          </h2>
          <h5 className="clients_second_title">
            20,000,000+ automated tests and counting!
          </h5>
        </div>

        <div className="mt_20">
          <div className="d-flex-space client-scroll-container">
            <div className="client-scroll-content">
              <div className="d-flex align_items_center">
                {doubledLogos.map((logo, index) => (
                  <div key={index}>
                    <img
                      className="logos_img_padding"
                      src={logo}
                      alt={`logo-${index}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HappyClients;

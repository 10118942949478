import React, {Component} from 'react';
import Reveal from 'react-reveal'
class HRServiceItems extends Component{
    render(){
        var{HRtitle, HRtitle1, HRdescription, featimg, spantext, Hicon, rclass, iclass} = this.props;
        return(
                <div className="col-lg-4 mb-30 col-sm-6">
                    <Reveal bottom cascade>
                    <div className="unifeat text-center">
                        <img src={featimg} alt="" / >
                        <h3>{HRtitle}</h3>
                        <h3>{HRtitle1}</h3>
                        <h5>{spantext}</h5>
                        <p>{HRdescription}</p>
                    </div>
                    </Reveal>
                </div>
            
        )
    }
}
export default HRServiceItems;
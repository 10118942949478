import React, { useState, useEffect } from "react";
import axios from "axios";
import AssetsRating from "./assets_ratings";
import CommonFaq from "../../components/Common/Faq";
import Skeleton from "react-loading-skeleton";

const AssetDescription = (props) => {
  console.log("AssetDescription", props);
  let [nlpdetails, setnlpDetails] = useState(props?.data);
  let [name,setname]=useState(props?.name);
  let [rating,setRaing]=useState(props?.rating)
  const [isLoading, setIsLoading] = useState(true);
  const [assetsType,setAssetsType]=useState("")
  
  useEffect(()=>{
    props?.setRerender(true)
    setIsLoading(false);
    getAllAssetsType()
    
  },[props])
  const getAllAssetsType = (id) => {
    let type = "";
    let assetstype =
      localStorage.hasOwnProperty("assets-type") &&
      JSON.parse(localStorage.getItem("assets-type"));
    assetstype.map((assets) => {
      if (assets.id === nlpdetails?.typeId) {
        type = assets.type;
        setAssetsType(assets.type)
        setIsLoading(false);
      }
    });
  };
  const rerender = ()=>{
    return(
      <section className="most_used_assets pb_30">
      <div className="container">
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-7">
            <div className="d-flex mt_40">
              {isLoading ? <Skeleton width={113} height={113} borderRadius={100} /> : <div className="excel_detail">
                <img
                  src={require("../../img/assets/excel-detail.svg").default}
                  alt=""
                />
              </div>}
              
              <div className="ml-3">
                <h3 className="asset_detail_head">{["elements","stepgroup"].includes(props?.label)?name:nlpdetails?.nlpName}</h3>
                <AssetsRating rating={["elements","stepgroup"].includes(props?.label)?rating:nlpdetails?.nlpRating} />
                <h4 className="number_of_download mt-3">
                  {nlpdetails?.downloads}{" "}
                  <span className="number_of_download_txt">Downloaded</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="ml-40">
            <p className="asset_detail_para  mt-3 mr-4">
              {nlpdetails?.description}
            </p>
          </div>
        </div>
        <div className="row mt_30">
          <div className="col-lg-6">
            <div className="asset_des_box ml-20">
              <h3 className="benefit_head prodcolor pt-3 pl_15">Top Benefit</h3>
              <div className="asset_head_line ml-40"></div>
              {nlpdetails?.benefit?.map((benefit) => {
                return (
                  <>
                    <div className="d-flex mt-3 pl_15">
                      <div className="benefit_star mr-2 fa fa-star"></div>
                      <h4 className="benefit_txt">{benefit}</h4>
                    </div>
                  </>
                );
              })}
             
            </div>
          </div>
          <div className="col-lg-6">
            <div className="asset_des_box mr_20 ">
              <h3 className="benefit_head prodcolor pt-3 pl_15">Information</h3>
              <div className="asset_head_line ml-60"></div>
              <div className="row">
                <div className="col-lg-6">
                  <table className="table asset_des_table ml_15">
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" className="asset_info_text">
                          Version:
                        </th>
                        <td className="asset_info_para">
                          {nlpdetails?.version}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="asset_info_text">
                          Type:
                        </th>
                        <td className="asset_info_para">

                          {assetsType}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="asset_info_text">
                          Last Update:
                        </th>
                        <td className="asset_info_para">
                          {nlpdetails?.modifiedOn}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="asset_info_text">
                          Published date:
                        </th>
                        <td className="asset_info_para">
                          {nlpdetails?.createdOn}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-6">
                  <table className="table asset_des_table ml-15">
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" className="asset_info_text">
                          Support:
                        </th>
                        <td className="asset_info_para">
                          {nlpdetails?.support?.toString()}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="asset_info_text">
                          Keywords
                        </th>
                        <td className="asset_info_para">
                          {nlpdetails?.keywords?.toString()}
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt_30">
          <div className="col-lg-6">
            <div className="asset_input ml-20">
              <div className="asset_input_head">
                <h3 className="mt-1  ml-3">Input</h3>
              </div>
              {nlpdetails?.input?.map((inputdata, index) => {
                return (
                  <>
                    <p className="mt-2 ml-3 mb-2">
                      {index + 1}. {inputdata}
                    </p>
                  </>
                );
              })}
             
            </div>
          </div>
          <div className="col-lg-6">
            <div className="asset_input mr_20">
              <div className="asset_input_head">
                <h3 className="mt-1 ml-3">Output</h3>
              </div>
              {nlpdetails?.output?.map((outputdata, index) => {
                return (
                  <>
                    <p className="mt-2 ml-3 mb-2">
                      {index + 1}. {outputdata}
                    </p>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
    )
  }
  return (
    // <section className="most_used_assets pb_30">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-lg-3"></div>
    //       <div className="col-lg-7">
    //         <div className="d-flex mt_40">
    //           <div className="excel_detail">
    //             <img
    //               src={require("../../img/assets/excel-detail.svg").default}
    //               alt=""
    //             />
    //           </div>
    //           <div className="ml-3">
    //             <h3 className="asset_detail_head">{nlpdetails?.nlpName}</h3>
    //             <AssetsRating rating={nlpdetails?.nlpRating} />
    //             {/* <span className="fa fa-star asset_rating mt-2"></span> */}
    //             <h4 className="number_of_download mt-3">
    //               {nlpdetails?.downloads}{" "}
    //               <span className="number_of_download_txt">Downloaded</span>
    //             </h4>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-2"></div>
    //       <div className="ml-40">
    //         <p className="asset_detail_para  mt-3 mr-4">
    //           {nlpdetails?.description}
    //         </p>
    //       </div>
    //     </div>
    //     <div className="row mt_30">
    //       <div className="col-lg-6">
    //         <div className="asset_des_box ml-20">
    //           <h3 className="benefit_head prodcolor pt-3 pl_15">Top Benefit</h3>
    //           <div className="asset_head_line ml-40"></div>
    //           {nlpdetails?.benefit?.map((benefit) => {
    //             return (
    //               <>
    //                 <div className="d-flex mt-3 pl_15">
    //                   <div className="benefit_star mr-2 fa fa-star"></div>
    //                   <h4 className="benefit_txt">{benefit}</h4>
    //                 </div>
    //               </>
    //             );
    //           })}
    //           {/* <div className="d-flex mt-3 pl_15">
    //             <div className="benefit_star mr-2 fa fa-star"></div>
    //             <h4 className="benefit_txt">
    //               Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
    //               diam nonumy
    //             </h4>
    //           </div>
    //           <div className="d-flex mt-3 pl_15">
    //             <div className="benefit_star mr-2 fa fa-star"></div>
    //             <h4 className="benefit_txt">
    //               Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
    //               diam nonumy
    //             </h4>
    //           </div>
    //           <div className="d-flex mt-3 pl_15">
    //             <div className="benefit_star mr-2 fa fa-star"></div>
    //             <h4 className="benefit_txt">
    //               Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
    //               diam nonumy
    //             </h4>
    //           </div> */}
    //         </div>
    //       </div>
    //       <div className="col-lg-6">
    //         <div className="asset_des_box mr_20 ">
    //           <h3 className="benefit_head prodcolor pt-3 pl_15">Information</h3>
    //           <div className="asset_head_line ml-60"></div>
    //           <div className="row">
    //             <div className="col-lg-6">
    //               <table className="table asset_des_table ml_15">
    //                 <thead>
    //                   <tr></tr>
    //                 </thead>
    //                 <tbody>
    //                   <tr>
    //                     <th scope="row" className="asset_info_text">
    //                       Version:
    //                     </th>
    //                     <td className="asset_info_para">
    //                       {nlpdetails?.version}
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <th scope="row" className="asset_info_text">
    //                       Type:
    //                     </th>
    //                     <td className="asset_info_para">
    //                       {getAllAssetsType(nlpdetails?.typeId)}
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <th scope="row" className="asset_info_text">
    //                       Last Update:
    //                     </th>
    //                     <td className="asset_info_para">
    //                       {nlpdetails?.modifiedOn}
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <th scope="row" className="asset_info_text">
    //                       Published date:
    //                     </th>
    //                     <td className="asset_info_para ml-4">
    //                       {nlpdetails?.createdOn}
    //                     </td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>
    //             <div className="col-lg-6">
    //               <table className="table asset_des_table ml_15">
    //                 <thead>
    //                   <tr></tr>
    //                 </thead>
    //                 <tbody>
    //                   <tr>
    //                     <th scope="row" className="asset_info_text">
    //                       Support:
    //                     </th>
    //                     <td className="asset_info_para">
    //                       {/* {nlpdetails?.support[0]} */}
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <th scope="row" className="asset_info_text">
    //                       Keywords
    //                     </th>
    //                     <td className="asset_info_para">
    //                       {/* {nlpdetails?.keywords[0]} */}
    //                     </td>
    //                   </tr>
    //                   {/* <tr>
    //                   <th scope="row" className="asset_info_text">
    //                     Last Update:
    //                   </th>
    //                   <td className="asset_info_para">22/10/2023</td>
    //                 </tr>
    //                 <tr>
    //                   <th scope="row" className="asset_info_text">
    //                     Published date:
    //                   </th>
    //                   <td className="asset_info_para ml-4">01/08/2023</td>
    //                 </tr> */}
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row mt_30">
    //       <div className="col-lg-6">
    //         <div className="asset_input ml-20">
    //           <div className="asset_input_head">
    //             <h3 className="mt-1  ml-3">Input</h3>
    //           </div>
    //           {nlpdetails?.input?.map((inputdata, index) => {
    //             return (
    //               <>
    //                 <p className="mt-2 ml-3 mb-2">
    //                   {index + 1}. {inputdata}
    //                 </p>
    //               </>
    //             );
    //           })}
    //           {/* <p className="mt-2 ml-3 mb-2">1. Wookbook</p>
    //           <p className="ml-3 mb-2">2. Shut name</p>
    //           <p className="ml-3 mb-3">3. Unique Value</p> */}
    //         </div>
    //       </div>
    //       <div className="col-lg-6">
    //         <div className="asset_input mr_20">
    //           <div className="asset_input_head">
    //             <h3 className="mt-1 ml-3">Output</h3>
    //           </div>
    //           {nlpdetails?.output?.map((outputdata, index) => {
    //             return (
    //               <>
    //                 <p className="mt-2 ml-3 mb-2">
    //                   {index + 1}. {outputdata}
    //                 </p>
    //               </>
    //             );
    //           })}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <>
    {
      rerender()
    }
    </>
  );
};

export default AssetDescription;

import React, { useEffect, useState } from "react";

const WhenToUse = (props) => {
  const [ScenariosList,setScenariosList]=useState();
  useEffect(()=>{
    handleWhentoUse(props?.ScenariosList)

  },[props])
  const handleWhentoUse =(data)=>{
    setScenariosList(data)
  }
  return (
    <section className="mt_30">
      <div className="container">
      
        <div>
      <h2 className="when_to_use prodcolor ml-0">When to use</h2>
      <div className="asset_head_line ml-70"></div>
      </div>
      <div className="row">
      <div className="excel mt_40">
        <div className="col-lg-12">
          {
            ScenariosList?.map((scenarios,index)=>{
              if(index < 3 ){
                return(
                  <>
                   <h3 className="benefit_head prodcolor mt-3">{scenarios?.scenarioOrder}</h3>
              <div className="asset_head_line ml-40"></div>
              <div className="d-flex mt-3">
                <div className="benefit_star mr-2 fa fa-star"></div>
                <h4 className="benefit_txt ">
                {scenarios?.Description}
                </h4>
              </div>
                  </>
                )
              }
             
            })
          }
          
           
            {/* <h3 className="benefit_head prodcolor mt-1">Scenario 1</h3>
            <div className="asset_head_line ml-40"></div>
            <div className="d-flex mt-3">
              <div className="benefit_star mr-2 fa fa-star"></div>
              <h4 className="benefit_txt ">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet
              </h4>
            </div> */}
          </div>
        </div>
      </div>
      </div>
      </section>
  );
};

export default WhenToUse;

export function openDeepLink(deepLink, fallbackUrl,history) {
    console.log("inside deeplink");
    var start = Date.now();
    var timeout = setTimeout(function() {
        var end = Date.now();
        if (end - start < 1500) {
            // If the user returns to this page within 1.5 seconds, the app is probably not installed
            // window.location.href = fallbackUrl;
            history.push(fallbackUrl)
            console.log("app is not installed");
        }
    }, 1000);

    // Try to open the deep link
    window.location.href = deepLink;

    // Clear the timeout if the user leaves the page
    window.addEventListener('blur', function() {
        clearTimeout(timeout);
    });
}
import React, { Component } from "react";
import Reveal from "react-reveal";
import freesignup from "../img/price/free-signup.png";
import support from "../img/home/support.png";
// import { Link } from "react-router-dom";

const GetStarted = () => {
  const sendEmailQueryParam = (e) => {
    console.log(e.target.value);
  };

  const redirectToFireFlink = () => {
    // history.push("https://app.fireflink.com/signup");
    // return <a href="https://app.fireflink.com/signup"></a>;
  };

  return (
    <section className="getstarted sec_pad">
      <div className="container">
        <div className="row ">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <Reveal bottom cascade>
              <h2>Ready to get Started?</h2>
            </Reveal>
            <Reveal effect="fadeInUp">
              <p className="f_400 mb_40">Sign up to use FireFlink for FREE!</p>
            </Reveal>
            <div className="getstartedform">
              <form
                className="mailchimp wow fadeInUp"
                data-wow-delay="0.6s"
                method="post"
              >
                <div className="sce_btn subcribes">
                  {/* <input
                    type="text"
                    name="EMAIL"
                    className="form-control memail"
                    placeholder="Enter Your Email Address"
                    onChange={sendEmailQueryParam}
                  /> */}
                  <a
                    href="/signup"
                    // onClick={redirectToFireFlink}
                    className="btn btn_submit f_size_15 f_500"
                    type="submit"
                  >
                    Start Free Testing
                  </a>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="row mt_40">
          <div className="col-lg-3"></div>
          <div className="col-lg-3 mb_10">
            <h3>Free Signup</h3>
            <div className="img_center">
              <img src={freesignup} alt="Free signup" />
            </div>
          </div>
          <div className="col-lg-3">
            <h3>24/7 Support</h3>
            <div className="img_center">
              <img src={support} alt="24/7 Support" />
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
